import { AsyncFn, IsoDateString } from '../../../common'
import { CompanyGuid, CompanySimpleItemLimitRequest, ForCompanyEntityGuid } from '../../Company/Company'
import { MaintenancePlanMinimalInfo } from '../../MaintenancePlans/MaintenancePlanTypes'
import {
  CompanyGuidWithMerchantIdTimeWindowRequest,
  MerchantIdTimeWindowDtoRequest,
  PaymentRecordGuid,
} from '../Payments/PaymentTypes'
import { PaymentRefundGuid } from '../Payments/RefundTypes'

export const ESTIMATED_PAYOUT_DEPOSIT_TRANSIT_DAYS = 3

export type PayoutGuid = string
export type PayoutItemGuid = string
export type PayoutGuidContainer = {
  payoutGuid: PayoutGuid
}

export type PayoutSummary = {
  payoutGuid?: PayoutGuid
  companyGuid: CompanyGuid
  externalPayoutSource: string
  externalPayoutId: string
  paidAt: IsoDateString
  netAmountUsc: number
  refundsAmountUsc: number
  refundsCount: number
  chargesAmountUsc: number
  chargesCount: number
  feesAmountUsc: number
  feesCount: number
  balanceAdjustmentUsc: number
  balanceAdjustmentDescription: string
  grossAmountUsc: number
  createdAt: IsoDateString
}

export type PayoutItemType = 'CHARGE' | 'FEE' | 'REFUND'

export type PayoutItem = {
  payoutItemGuid: PayoutItemGuid
  payoutGuid?: PayoutGuid
  itemType: PayoutItemType
  itemAmountUsc: number
  itemDescription: string
  externalItemId: string
  paymentRecordGuid?: PaymentRecordGuid
  paymentRefundGuid?: PaymentRefundGuid
  occurredAt: IsoDateString
  createdAt: IsoDateString
  sourcePayoutItemGuid?: PayoutItemGuid
}

export type Payout = PayoutSummary & {
  items: PayoutItem[]
}

export type ExternalPayoutIdAndMerchantIdRequest = {
  externalPayoutId: string
  merchantId: string
}

/**
 * Represents a payout which is an amount of money sent to a merchant's bank account.
 * Currently, these Payouts might originate from Tilled.
 */
export type ExternalPayoutIdContainer = {
  externalPayoutId: string
}

/** Reads all the payout ids which occurred for a given company within a given time window. */
export type PayoutsReader = AsyncFn<MerchantIdTimeWindowDtoRequest, ExternalPayoutIdContainer[]>

/** Reads a payout by its Breezy Payout Guid. */
export type PayoutReader = AsyncFn<PayoutGuidContainer, Payout>

export type PayoutAlreadyExists = {
  alreadyExists: true
}

export const isPayoutWriterResponseAlreadyExists = (
  res: PayoutGuidContainer | PayoutAlreadyExists,
): res is PayoutAlreadyExists => !!(res as PayoutAlreadyExists).alreadyExists

/** Writes a payout to persistence */
export type PayoutWriter = AsyncFn<Payout, PayoutGuidContainer | PayoutAlreadyExists>

/** Enqueue payout sync task */
export type PayoutReconcileRequestPublisher = AsyncFn<ExternalPayoutIdAndMerchantIdRequest>

/** Reads a payout by its External Payout Id. */
export type ExternalPayoutReader = AsyncFn<ExternalPayoutIdAndMerchantIdRequest, Payout>

/**
 * Requests a system reconciliation of an payout by it's external payout id.
 * This might be triggered upon a webhook from an external system. Expects
 * at minimum that the Breezy payouts data now contains the payout, and perhaps also
 * that other external finance systems (such as any Finance Apps) are kept in sync.
 */
export type PayoutReconciler = AsyncFn<ExternalPayoutIdAndMerchantIdRequest>

/** Requests that the Breezy system synchronize all payouts within a time window */
export type PayoutsSync = AsyncFn<CompanyGuidWithMerchantIdTimeWindowRequest>

/** Detailed Payout Item View Model with rich link information */
export type PayoutItemEnriched = PayoutItem & {
  invoiceGuid?: string
  invoiceDisplayId?: string
  jobGuid?: string
  maintenancePlanGuid?: string
  accountDisplayName?: string
  accountGuid?: string
  accountMaintenancePlan?: MaintenancePlanMinimalInfo
  qboLink?: string
}

/** Detailed Payout View Model with rich item link information */
export type PayoutEnriched = PayoutSummary & {
  items: PayoutItemEnriched[]
  accountingSyncInfo?: {
    externalId: string
    syncedAt: IsoDateString
  }
}

/** Reads all Payout View Models within a given time window */
export type PayoutsEnrichedReader = AsyncFn<CompanySimpleItemLimitRequest, PayoutEnriched[]>

/** Reads a Payout View Model by Payout Guid */
export type PayoutEnrichedReader = AsyncFn<ForCompanyEntityGuid, PayoutEnriched>
