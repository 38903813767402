import { z } from 'zod'
import { bzOptional, EquipmentType } from '..'
import { guidSchema } from '../../contracts'
import { ONLINE_BOOKING_SERVICE_TYPES } from '../OnlineBooking/OnlineBooking'

export const JOB_LEAD_STATUSES = ['open', 'converted', 'archived'] as const

export type JobLeadStatus = (typeof JOB_LEAD_STATUSES)[number]

export const MAINTENANCE_EQUIPMENT_TYPES = [
  EquipmentType.AIR_CONDITIONER,
  EquipmentType.BOILER,
  EquipmentType.GAS_FURNACE,
  EquipmentType.GENERATOR,
  EquipmentType.HEAT_PUMP,
  EquipmentType.HUMIDIFIER,
  EquipmentType.DEHUMIDIFIER,
  EquipmentType.WATER_HEATER,
] as const satisfies EquipmentType[]

export const SERVICE_EQUIPMENT_TYPES = [
  ...MAINTENANCE_EQUIPMENT_TYPES,
  EquipmentType.AIR_PURIFIER,
  EquipmentType.THERMOSTAT,
] as const satisfies EquipmentType[]

export const JOB_LEAD_EQUIPMENT_TYPES = [...MAINTENANCE_EQUIPMENT_TYPES, ...SERVICE_EQUIPMENT_TYPES] as const
export type JobLeadEquipmentTypeArray = Array<(typeof JOB_LEAD_EQUIPMENT_TYPES)[number]>

export const JOB_LEAD_ESTIMATE_TYPES = ['COOLING_SYSTEM', 'HEATING_SYSTEM', 'FULL_SYSTEM'] as const
export type JobLeadEstimateType = (typeof JOB_LEAD_ESTIMATE_TYPES)[number]
export const JobLeadEstimateTypeDisplayNames: Record<JobLeadEstimateType, string> = {
  COOLING_SYSTEM: 'Cooling',
  HEATING_SYSTEM: 'Heating',
  FULL_SYSTEM: 'Full System',
}

export const JobLeadAvailabilitySchema = z.object({
  times: z.array(
    z.object({
      type: z.string(),
      value: z.string(),
    }),
  ),
})

export type JobLeadAvailability = z.infer<typeof JobLeadAvailabilitySchema>

const PhoneCallMetadata = z.object({
  type: z.literal('phone-call'),
  data: z.object({
    externalCallId: bzOptional(z.string()),
    summary: bzOptional(z.string()),
    transcript: bzOptional(z.string()),
    recordingUrl: bzOptional(z.string()),
  }),
})

export const JobLeadAdditionalMetadataSchema = z.discriminatedUnion('type', [PhoneCallMetadata])

export type JobLeadAdditionalMetadata = z.infer<typeof JobLeadAdditionalMetadataSchema>

export const CreateJobLeadRequestSchema = z.object({
  jobLeadGuid: bzOptional(guidSchema),
  preExistingLocationGuid: bzOptional(guidSchema),
  contactFirstName: bzOptional(z.string().describe('First Name')),
  contactLastName: bzOptional(z.string().describe('Last Name')),
  contactPhoneNumber: bzOptional(z.string().describe('Phone Number')),
  contactEmailAddress: bzOptional(z.string().describe('Email Address')),
  serviceAddressLine1: bzOptional(z.string().describe('Service Location Line 1')),
  serviceAddressLine2: bzOptional(z.string().describe('Service Location Line 2')),
  serviceAddressCity: bzOptional(z.string().describe('Service Location City')),
  serviceAddressStateAbbreviation: bzOptional(z.string().describe('Service Location State')),
  serviceAddressZipCode: bzOptional(z.string().describe('Service Location Zip Code')),
  accountType: bzOptional(z.string().describe('Type of Customer')),
  requestType: bzOptional(z.enum(ONLINE_BOOKING_SERVICE_TYPES).describe('Type of Request')),
  /** @deprecated */
  equipmentTypes: bzOptional(
    z.array(z.enum([...MAINTENANCE_EQUIPMENT_TYPES, ...SERVICE_EQUIPMENT_TYPES])).describe('Equipment Types'),
  ),
  /** @deprecated */
  estimateType: bzOptional(z.enum(JOB_LEAD_ESTIMATE_TYPES).describe('Estimate Type')),
  /** @deprecated */
  jobType: bzOptional(z.string().describe('Type of Service')),

  submittedJobTypeGuid: bzOptional(guidSchema),
  appointmentAvailabilities: bzOptional(
    JobLeadAvailabilitySchema.describe('Appointment Availability').or(z.string().array()),
  ),
  additionalMetadata: bzOptional(JobLeadAdditionalMetadataSchema),
  jobSummary: bzOptional(z.string().describe('Additional Details')),
  leadSource: bzOptional(z.string().describe('Lead Source')),
})

export type CreateJobLeadRequest = z.infer<typeof CreateJobLeadRequestSchema>
