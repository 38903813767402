import { Button } from 'antd'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  ContactCard,
  ContactCardContact,
} from 'src/components/Cards/ContactCard'

export type ContactCollapsibleCardActions = {
  onContactEdit?: (contact: ContactCardContact) => void
  onContactMakePrimary?: (contact: ContactCardContact) => void
  onContactArchive?: (contact: ContactCardContact) => void
  onContactUnarchive?: (contact: ContactCardContact) => void
  onContactDelete?: (contact: ContactCardContact) => void
}

export type ContactsCollapsibleProps = ContactCollapsibleCardActions & {
  contacts: ContactCardContact[]
  title?: string
  editable?: boolean
  disabled?: boolean
  onContactAdd?: () => void
  mode?: OnsitePageCollapsibleMode
} & (
    | {
        mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
        total?: never
        onViewMore?: never
      }
    | {
        mode: 'paginated'
        total: number
        onViewMore: () => void
      }
  )

export const ContactsCollapsible = ({
  mode = 'default',
  ...props
}: ContactsCollapsibleProps) => {
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title={props.title ?? 'Point of Contact'}
      count={props.total ?? props.contacts.length}
      defaultCollapsed={props.contacts.length === 0}
      onAdd={props.editable ? props.onContactAdd : undefined}
      addButtonTestId="contacts-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {props.contacts.map(contact => (
          <ContactCard
            key={contact.contactGuid}
            contact={contact}
            onEdit={props.editable ? props.onContactEdit : undefined}
            onMakePrimary={
              props.editable ? props.onContactMakePrimary : undefined
            }
            onArchive={props.editable ? props.onContactArchive : undefined}
            onUnarchive={props.editable ? props.onContactUnarchive : undefined}
            onDelete={props.editable ? props.onContactDelete : undefined}
          />
        ))}
        {mode === 'paginated' &&
          props.onViewMore &&
          props.total &&
          props.total > props.contacts.length && (
            <Button className="w-fit" type="link" onClick={props.onViewMore}>
              View {props.total - props.contacts.length} more contacts
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
