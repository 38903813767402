import BzColumn from '../../elements/BzColumn/BzColumn'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { AccountWrapper, Editable } from './accountDetailsV2Utils'
import { AccountEstimatesCollapsible } from './collapsibles/AccountEstimatesCollapsible'
import { AccountInvoicesCollapsible } from './collapsibles/AccountInvoicesCollapsible'
import { AccountJobsCollapsible } from './collapsibles/AccountJobsCollapsible'
import { AccountMaintenancePlansCollapsible } from './collapsibles/AccountMaintenancePlansCollapsible'
import { AccountPaymentsCollapsible } from './collapsibles/AccountPaymentsCollapsible'
import { AccountVisitsCollapsible } from './collapsibles/AccountVisitsCollapsible'
import { AccountWisetackFinancingCollapsible } from './collapsibles/AccountWisetackFinancingCollapsible'

export const AccountDetailsRightSidePane = ({
  meta,
  editable = true,
}: Editable<AccountWrapper>) => {
  const { accountGuid } = meta

  const wisetackEnabled = useWisetackEnabled()

  return (
    <BzColumn noPaddingMobile>
      <AccountMaintenancePlansCollapsible accountGuid={accountGuid} />

      <AccountJobsCollapsible accountGuid={accountGuid} editable={editable} />
      <AccountVisitsCollapsible accountGuid={accountGuid} editable={editable} />
      {wisetackEnabled && (
        <AccountWisetackFinancingCollapsible accountGuid={accountGuid} />
      )}
      <AccountPaymentsCollapsible accountGuid={accountGuid} />
      <AccountInvoicesCollapsible accountGuid={accountGuid} />
      <AccountEstimatesCollapsible accountGuid={accountGuid} />
    </BzColumn>
  )
}
