import {
  MaintenancePlanOpportunityMinimalInfo,
  MaintenancePlanStatus,
} from '@breezy/shared'
import { DocumentType, gql } from '../../generated'

export const MAINTENANCE_PLAN_MINIMAL_FRAGMENT = gql(/* GraphQL */ `
  fragment MaintenancePlanMinimal on MaintenancePlansQueryable {
    maintenancePlanGuid
    status
    paymentFlow
    maintenancePlanDefinition {
      ...MaintenancePlanDefinitionMinimal
    }
  }
`)

export type MaintenancePlanMinimalFragment = DocumentType<
  typeof MAINTENANCE_PLAN_MINIMAL_FRAGMENT
>

export const MAINTENANCE_PLAN_DEFINITION_MINIMAL_FRAGMENT = gql(/* GraphQL */ `
  fragment MaintenancePlanDefinitionMinimal on MaintenancePlanDefinitions {
    marketingInfo {
      name
    }
    flare {
      imageUrl
      primaryColorHex
    }
  }
`)

export type MaintenancePlanDefinitionMinimalFragment = DocumentType<
  typeof MAINTENANCE_PLAN_DEFINITION_MINIMAL_FRAGMENT
>

export const MAINTENANCE_PLAN_OPPORTUNITY_MINIMAL_INFO_FRAGMENT =
  gql(/* GraphQL */ `
    fragment MaintenancePlanOpportunityMinimalInfo on MaintenancePlansQueryable {
      maintenancePlanGuid
      accountGuid
      locationGuid
      maintenancePlanDefinition {
        numVisitCreditsPerYear
        marketingInfo {
          name
        }
      }
      status
      credits
      lastVisitedAt
    }
  `)

type MaintenancePlanOpportunityMinimalInfoFragment = DocumentType<
  typeof MAINTENANCE_PLAN_OPPORTUNITY_MINIMAL_INFO_FRAGMENT
>

export const toMaintenancePlanOpportunityMinimalInfo = (
  opportunity: MaintenancePlanOpportunityMinimalInfoFragment,
): MaintenancePlanOpportunityMinimalInfo => {
  return {
    maintenancePlanGuid: opportunity.maintenancePlanGuid,
    accountGuid: opportunity.accountGuid,
    locationGuid: opportunity.locationGuid,
    planTypeName:
      opportunity.maintenancePlanDefinition?.marketingInfo?.name ?? '',
    status: opportunity.status as MaintenancePlanStatus,
    numVisitCreditsPerYear:
      opportunity.maintenancePlanDefinition?.numVisitCreditsPerYear ?? 0,
    lastVisitedAt: opportunity.lastVisitedAt,
  }
}
