import { TimeZoneId } from '@breezy/shared'
import { Button } from 'antd'
import { useCallback, useState } from 'react'
import { OnsiteBasicModal } from 'src/adam-components/OnsiteModal/OnsiteModal'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  VisitCard,
  VisitCardProps,
  VisitCardVisit,
} from 'src/components/Cards/VisitCard'
import { useModalState } from '../../../utils/react-utils'

export type VisitsCollapsibleProps = {
  visits: VisitCardVisit[]
  timezoneId: TimeZoneId
  editable?: boolean
  onCreateVisit?: () => void
  total?: number
  onEditVisit?: VisitCardProps['onEdit']
  onCancelVisit?: VisitCardProps['onCancelVisit']
  onViewChecklist?: VisitCardProps['onViewChecklist']
  mode?: OnsitePageCollapsibleMode
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const VisitsCollapsible = ({
  mode = 'default',
  ...props
}: VisitsCollapsibleProps) => {
  const {
    descriptionModalOpen,
    onViewDescription,
    ...visitDescriptionModalProps
  } = useVisitDescriptionModal()

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Visits"
      count={props.total ?? props.visits.length}
      defaultCollapsed={props.visits.length === 0}
      onAdd={props.editable ? props.onCreateVisit : undefined}
    >
      <div className="flex flex-col gap-3">
        {props.visits.map(visit => (
          <VisitCard
            key={visit.appointment.appointmentGuid}
            visit={visit}
            editable={props.editable}
            timezoneId={props.timezoneId}
            onEdit={props.onEditVisit}
            onCancelVisit={props.onCancelVisit}
            onViewDescription={onViewDescription}
            onViewChecklist={props.onViewChecklist}
          />
        ))}
        {mode === 'paginated' &&
          props.onViewMore &&
          props.total &&
          props.total > props.visits.length && (
            <Button className="w-fit" type="link" onClick={props.onViewMore}>
              View {props.total - props.visits.length} more visits
            </Button>
          )}
      </div>

      {descriptionModalOpen && (
        <VisitDescriptionModal {...visitDescriptionModalProps} />
      )}
    </OnsitePageCollapsibleSection>
  )
}

export const useVisitDescriptionModal = () => {
  const [descriptionModalOpen, openDescriptionModal, closeDescriptionModal] =
    useModalState(false)

  const [description, setDescription] = useState<string | undefined>(undefined)

  const onViewDescription = useCallback(
    (description: string) => {
      setDescription(description)
      openDescriptionModal()
    },
    [openDescriptionModal, setDescription],
  )

  const onClose = useCallback(() => {
    setDescription(undefined)
    closeDescriptionModal()
  }, [closeDescriptionModal, setDescription])

  return {
    descriptionModalOpen,
    onViewDescription,
    description,
    onClose,
  }
}

type VisitDescriptionModalProps = {
  description?: string
  onClose: () => void
}

export const VisitDescriptionModal = ({
  description,
  onClose,
}: VisitDescriptionModalProps) => {
  return (
    <OnsiteBasicModal header="Visit Description" open onClose={onClose}>
      {description ? description : 'No description provided'}
    </OnsiteBasicModal>
  )
}
