import { TimeZoneId } from '@breezy/shared'
import { Button } from 'antd'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { JobCard, JobCardJob } from 'src/components/Cards/JobCard'

export type JobsCollapsibleProps = {
  jobs: JobCardJob[]
  timezoneId: TimeZoneId
  canManage?: boolean
  onAddJob?: () => void
  mode?: OnsitePageCollapsibleMode
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const JobsCollapsible = ({
  mode = 'default',
  ...props
}: JobsCollapsibleProps) => {
  const { jobs } = props

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Jobs"
      count={props.total ?? jobs.length}
      defaultCollapsed={jobs.length === 0}
      onAdd={props.canManage ? props.onAddJob : undefined}
      addButtonTestId="jobs-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {jobs.map(job => (
          <JobCard key={job.jobGuid} job={job} timezoneId={props.timezoneId} />
        ))}
        {mode === 'paginated' &&
          props.onViewMore &&
          props.total &&
          props.total > props.jobs.length && (
            <Button className="w-fit" type="link" onClick={props.onViewMore}>
              View {props.total - props.jobs.length} more jobs
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
