import { BzDateFns, LoanRecord, LoanRecordStatus } from '@breezy/shared'
import { FetchLoanRecordQuery } from '../../query'

export type FetchLoanRecord = FetchLoanRecordQuery['wisetackLoanRecords'][number]
export type FetchLoanRecordStatus = FetchLoanRecordQuery['wisetackLoanRecords'][number]['wisetackLoanStatuses'][number]

export const convertFetchLoanRecordStatusToLoanRecordStatus = (input: FetchLoanRecordStatus): LoanRecordStatus => ({
  loanStatus: input.loanStatus,
  loanStatusDetail: input.loanStatusDetail,
  occurredAt: input.occurredAt ?? BzDateFns.nowISOString(),
  createdAt: input.createdAt ?? BzDateFns.nowISOString(),
})

export const convertFetchLoanRecordToLoanRecord = (input: FetchLoanRecord): LoanRecord => ({
  loanRecordGuid: input.wisetackLoanRecordGuid,
  companyGuid: input.companyGuid,
  accountGuid: input.accountGuid,
  invoiceGuid: input.invoiceGuid,
  contactGuid: input.contactGuid,
  jobGuid: input.jobGuid,
  merchantGuid: input.wisetackMerchantGuid,
  externalTransactionGuid: input.wisetackTransactionGuid,
  requestedLoanAmountUsc: input.requestedLoanAmountUsc,
  approvedLoanAmountUsc: input.approvedLoanAmountUsc,
  settledLoanAmountUsc: input.settledLoanAmountUsc,
  processingFeeUsc: input.processingFeeUsc,
  loanApplicationLink: input.loanApplicationLink,
  createdAt: input.createdAt ?? BzDateFns.nowISOString(),
  updatedAt: input.updatedAt ?? BzDateFns.nowISOString(),
  expiresAt: input.expiresAt,
  loanRecordStatuses: input.wisetackLoanStatuses.map(convertFetchLoanRecordStatusToLoanRecordStatus),
  latestLoanRecordStatus: input.wisetackLoanStatuses
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    .map(convertFetchLoanRecordStatusToLoanRecordStatus)[0],
  contactName: input.contact.fullName ?? '',
})
