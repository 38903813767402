import { gql } from '../../generated'

export const JOB_REQUEST_FRAGMENT = gql(/* GraphQL */ `
  fragment JobRequestFragment on JobLeads {
    jobLeadGuid
    contactFirstName
    contactLastName
    contactPhoneNumber
    contactEmailAddress
    serviceAddressLine1
    serviceAddressLine2
    serviceAddressCity
    serviceAddressStateAbbreviation
    serviceAddressZipCode
    accountType
    jobType
    requestType
    equipmentTypes
    estimateType
    appointmentAvailabilities
    additionalMetadata
    createdAt
    jobSummary
    leadSource
    jobLeadStatus
    jobGuid
    submittedJobType {
      jobTypeGuid
      name
    }
    job {
      displayId
      createdAt
      jobType {
        name
      }
      account {
        accountGuid
        accountDisplayName
      }
      location {
        maintenancePlans(
          where: { canceledAt: { _isNull: true } }
          orderBy: { yearlyPriceUsc: DESC }
        ) {
          maintenancePlanDefinition {
            marketingInfo {
              name
            }
            flare {
              primaryColorHex
            }
          }
        }
      }
    }
    preExistingLocation {
      maintenancePlans(
        where: { canceledAt: { _isNull: true } }
        orderBy: { yearlyPriceUsc: DESC }
      ) {
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
          flare {
            primaryColorHex
          }
        }
      }
    }
  }
`)

export const FETCH_JOB_LEADS = gql(/* GraphQL */ `
  query FetchJobLeads(
    $companyGuid: uuid!
    $timeStart: timestamptz
    $timeEnd: timestamptz
  ) {
    jobLeads(
      where: {
        companyGuid: { _eq: $companyGuid }
        createdAt: { _gte: $timeStart, _lte: $timeEnd }
      }
      orderBy: { createdAt: DESC }
    ) {
      ...JobRequestFragment
    }
  }
`)

export const FETCH_JOB_LEADS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription FetchJobLeadsSubscription(
    $companyGuid: uuid!
    $timeStart: timestamptz
    $timeEnd: timestamptz
  ) {
    jobLeads(
      where: {
        companyGuid: { _eq: $companyGuid }
        createdAt: { _gte: $timeStart, _lte: $timeEnd }
      }
      orderBy: { createdAt: DESC }
    ) {
      ...JobRequestFragment
    }
  }
`)

export const FETCH_JOB_LEAD_BY_JOB_LEAD_GUID = gql(/* GraphQL */ `
  query FetchJobLeadByJobLeadGuid($jobLeadGuid: uuid!) {
    jobLeads(where: { jobLeadGuid: { _eq: $jobLeadGuid } }, limit: 1) {
      ...JobRequestFragment
    }
  }
`)

export const UPDATE_JOB_LEAD_STATUS = gql(/* GraphQL */ `
  mutation UpdateJobLeadStatus(
    $set: JobLeadsSetInput
    $companyGuid: uuid!
    $jobLeadGuid: uuid!
  ) {
    updateJobLeads(
      _set: $set
      where: {
        companyGuid: { _eq: $companyGuid }
        jobLeadGuid: { _eq: $jobLeadGuid }
      }
    ) {
      affectedRows
    }
  }
`)

export const CONVERT_JOB_LEAD = gql(/* GraphQL */ `
  mutation ConvertJobLead($jobLeadGuid: uuid!, $jobGuid: uuid!) {
    updateJobLeadsByPk(
      _set: { jobGuid: $jobGuid, jobLeadStatus: "converted" }
      pkColumns: { jobLeadGuid: $jobLeadGuid }
    ) {
      __typename
    }
  }
`)

export const FETCH_COUNT_OF_OPEN_JOB_LEADS = gql(/* GraphQL */ `
  subscription FetchCountOfOpenJobLeadsByCompany($companyGuid: uuid!) {
    jobLeadsAggregate(
      where: {
        companyGuid: { _eq: $companyGuid }
        jobLeadStatus: { _eq: "open" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`)
