import { convertFetchLoanRecordToLoanRecord } from '@breezy/backend/src/application-types'
import {
  AccountGuid,
  BzDateFns,
  LoanRecord,
  PrequalRecord,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'
import { WisetackFinancingCollapsibleV2 } from '../../../components/collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsibleV2'
import { DocumentType, gql } from '../../../generated/user'

const ACCOUNT_WISETACK_FINANCING_QUERY = gql(/* GraphQL */ `
  query AccountWisetackFinancing($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      wisetackLoanRecords {
        ...LoanRecord
      }
      wisetackPrequalRecords {
        ...PrequalRecord
      }
    }
  }
`)

type AccountWisetackFinancingQuery = DocumentType<
  typeof ACCOUNT_WISETACK_FINANCING_QUERY
>

const convertQueryToAccountLoanRecords = (
  wisetackLoanRecords: NonNullable<
    AccountWisetackFinancingQuery['accountsByPk']
  >['wisetackLoanRecords'],
): LoanRecord[] => {
  return wisetackLoanRecords.map(convertFetchLoanRecordToLoanRecord)
}

const convertQueryToAccountPrequalRecords = (
  wisetackPrequalRecords: NonNullable<
    AccountWisetackFinancingQuery['accountsByPk']
  >['wisetackPrequalRecords'],
): PrequalRecord[] => {
  return wisetackPrequalRecords.map(
    ({
      wisetackPrequalRecordGuid,
      wisetackMerchantGuid,
      wisetackPrequalGuid,
      createdAt,
      updatedAt,
      ...rest
    }) => ({
      prequalRecordGuid: wisetackPrequalRecordGuid,
      merchantGuid: wisetackMerchantGuid,
      externalPrequalGuid: wisetackPrequalGuid,
      // As with the loan records, these are nullable for no discernable reason.
      createdAt: createdAt ?? BzDateFns.nowISOString(),
      updatedAt: updatedAt ?? BzDateFns.nowISOString(),
      ...rest,
    }),
  )
}

const useFetchAccountWisetackFinancing = (accountGuid: AccountGuid) => {
  const accountWisetackFinancingQuery = useQuery({
    query: ACCOUNT_WISETACK_FINANCING_QUERY,
    variables: {
      accountGuid,
    },
  })

  const { loanRecords, prequalRecords } = useMemo(() => {
    const loanRecords = convertQueryToAccountLoanRecords(
      accountWisetackFinancingQuery[0].data?.accountsByPk
        ?.wisetackLoanRecords ?? [],
    )
    const prequalRecords = convertQueryToAccountPrequalRecords(
      accountWisetackFinancingQuery[0].data?.accountsByPk
        ?.wisetackPrequalRecords ?? [],
    )
    return { loanRecords, prequalRecords }
  }, [accountWisetackFinancingQuery])

  return {
    accountWisetackFinancingQuery,
    refetch: accountWisetackFinancingQuery[1],
    fetching: accountWisetackFinancingQuery[0].fetching,
    loanRecords,
    prequalRecords,
  }
}

type AccountWisetackFinancingCollapsibleProps = {
  accountGuid: AccountGuid
}

export const AccountWisetackFinancingCollapsible =
  React.memo<AccountWisetackFinancingCollapsibleProps>(({ accountGuid }) => {
    const { loanRecords, prequalRecords } =
      useFetchAccountWisetackFinancing(accountGuid)

    return (
      <WisetackFinancingCollapsibleV2
        accountGuid={accountGuid}
        loanRecords={loanRecords}
        prequalRecords={prequalRecords}
      />
    )
  })
