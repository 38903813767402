import { PermissionV2 } from '@breezy/shared'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useIsImpersonating } from '../../../providers/PrincipalUser'
import { Authorized } from '../../Permissions/Authorized/Authorized'

// This error button is used to test general error fallback, we can remove it once we have a better way to test it
export const TestErrorButton = React.memo(() => {
  const isImpersonating = useIsImpersonating()
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const undefinedObject = undefined as any
  const [showError, setShowError] = useState(false)
  return (
    <Authorized
      to={PermissionV2.IMPERSONATE_USER}
      overrideAuthorized={!!isImpersonating}
      allowsNonCompanyUser
    >
      <div className="mt-2 flex flex-row items-center">
        <Button
          id="test-error"
          type="link"
          onClick={() => {
            setShowError(true)
            throw new Error('Test Error')
          }}
          style={{ padding: 0 }}
        >
          Test Error
        </Button>
        {showError && (
          <div>
            {
              /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
              undefinedObject['frontEndTestErrrorPleaseIgnore']
            }
          </div>
        )}
      </div>
    </Authorized>
  )
})
