import { AccountGuid, CompanyGuid, FileRecord, R } from '@breezy/shared'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { gql } from '../../generated'
import { AccountFileRecordsQuery } from '../../generated/user/graphql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

const ACCOUNT_FILE_RECORDS_QUERY = gql(/* GraphQL */ `
  query AccountFileRecords($where: FileLinksBoolExp!) {
    fileLinksAggregate(where: $where) {
      aggregate {
        count
      }
    }
    fileLinks(where: $where) {
      ...FileRecord
    }
  }
`)

const convertQueryToFileRecords = (
  data: AccountFileRecordsQuery['fileLinks'],
  companyGuid: CompanyGuid,
) => {
  const fileLinks = data ?? []

  const unsortedFileRecords = fileLinks.map(
    ({ file: { createdByUserGuid, ...rest } }) => ({
      userGuid: createdByUserGuid,
      companyGuid,
      ...rest,
    }),
  )

  const sortedFileRecords = R.sortWith(
    [R.descend(R.prop('createdAt')), R.ascend(R.prop('fileName'))],
    unsortedFileRecords,
  )

  return sortedFileRecords
}

const useFetchAccountFileRecords = (accountGuid: AccountGuid) => {
  const companyGuid = useExpectedCompanyGuid()
  const accountFileRecordsQuery = useQuery({
    query: ACCOUNT_FILE_RECORDS_QUERY,
    variables: {
      where: {
        _or: [
          {
            accountGuid: { _eq: accountGuid },
          },
          {
            job: {
              accountGuid: { _eq: accountGuid },
            },
          },
          {
            jobAppointment: {
              job: {
                accountGuid: { _eq: accountGuid },
              },
            },
          },
          {
            jobAppointmentAssignment: {
              jobAppointment: {
                job: {
                  accountGuid: { _eq: accountGuid },
                },
              },
            },
          },
          {
            location: {
              accountLocations: {
                accountGuid: { _eq: accountGuid },
              },
            },
          },
        ],
      },
    },
  })

  const fileRecords = useMemo(() => {
    return convertQueryToFileRecords(
      accountFileRecordsQuery[0].data?.fileLinks ?? [],
      companyGuid,
    )
  }, [accountFileRecordsQuery, companyGuid])
  const total = useMemo(
    () =>
      accountFileRecordsQuery[0].data?.fileLinksAggregate?.aggregate?.count ??
      0,
    [accountFileRecordsQuery],
  )

  return {
    accountFileRecordsQuery,
    fileRecords,
    refetch: accountFileRecordsQuery[1],
    fetching: accountFileRecordsQuery[0].fetching,
    total,
  }
}

type WithAccountFileRecordsProps = {
  accountGuid: AccountGuid
  loadingComponent?: JSX.Element
  render: (fileRecords: FileRecord[]) => JSX.Element
}
export const WithAccountFileRecords = React.memo<WithAccountFileRecordsProps>(
  ({ accountGuid, render, loadingComponent }) => {
    const companyGuid = useExpectedCompanyGuid()
    const { accountFileRecordsQuery } = useFetchAccountFileRecords(accountGuid)

    return (
      <GqlQueryLoader
        query={accountFileRecordsQuery}
        loadingComponent={loadingComponent}
        idleComponent={loadingComponent}
        render={data => {
          return render(convertQueryToFileRecords(data.fileLinks, companyGuid))
        }}
      />
    )
  },
)
