import {
  BzDateFns,
  CardOnFile,
  getCardOnFileDisplayName,
  isNullish,
  PaymentMethodDisplayNames,
  TimeZoneId,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'antd'
import { MenuProps } from 'antd/lib'
import { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { CardOnFileStatusTag } from '../CardOnFileStatusTag/CardOnFileStatusTag'

export interface CardOnFileCardProps {
  cardOnFile: CardOnFile
  timezoneId: TimeZoneId
  onEditDisplayName?: (cardOnFile: CardOnFile) => void
  onRemoveCard?: (cardOnFile: CardOnFile) => void
}

export const CardOnFileCard = (props: CardOnFileCardProps) => {
  const { cardOnFile } = props

  const dropdownItems = useMemo<MenuProps['items']>(() => {
    const items: NonNullable<MenuProps['items']> = []

    if (!isNullish(props.onEditDisplayName)) {
      items.push({
        label: 'Edit Display Name',
        key: `${cardOnFile.paymentMethodRecordGuid}-edit-display-name`,
      })
    }

    if (!isNullish(props.onRemoveCard)) {
      items.push({
        label: cardOnFile.hasPaymentSubscription
          ? 'Remove Card (Tied to Maintenance Plan)'
          : 'Remove Card',
        key: `${cardOnFile.paymentMethodRecordGuid}-remove-card`,
      })
    }

    return items
  }, [
    cardOnFile.hasPaymentSubscription,
    cardOnFile.paymentMethodRecordGuid,
    props.onEditDisplayName,
    props.onRemoveCard,
  ])

  const onDropdownItemClick: NonNullable<MenuProps['onClick']> = useCallback(
    ({ key }) => {
      switch (key) {
        case `${cardOnFile.paymentMethodRecordGuid}-edit-display-name`:
          props.onEditDisplayName?.(cardOnFile)
          break
        case `${cardOnFile.paymentMethodRecordGuid}-remove-card`:
          props.onRemoveCard?.(cardOnFile)
          break
        default:
          break
      }
    },
    [cardOnFile, props],
  )

  return (
    <SectionedCard
      dashed
      small
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div
              className="flex flex-row items-center justify-between"
              data-testid="maintenance-plan-card-title"
            >
              {getCardOnFileDisplayName(cardOnFile)}

              <div className="flex flex-row gap-1">
                <CardOnFileStatusTag cardOnFile={cardOnFile} />

                <Dropdown
                  menu={{ items: dropdownItems, onClick: onDropdownItemClick }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    className="cursor-pointer"
                  />
                </Dropdown>
              </div>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Type',
                PaymentMethodDisplayNames[cardOnFile.paymentMethodType],
                'Name',
                cardOnFile.paymentMethodBillingInfo?.name ?? <EmDash />,
                ...(cardOnFile.paymentMethodCardMetadata?.expMonth &&
                cardOnFile.paymentMethodCardMetadata?.expYear
                  ? [
                      'Expiration',
                      `${cardOnFile.paymentMethodCardMetadata?.expMonth}/
                ${cardOnFile.paymentMethodCardMetadata?.expYear}`,
                    ]
                  : []),
                ...(!isNullish(cardOnFile.createdAt)
                  ? [
                      'Date Added',
                      BzDateFns.format(
                        BzDateFns.parseISO(
                          cardOnFile.createdAt,
                          props.timezoneId,
                        ),
                        'MMM d, yyyy',
                      ),
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
