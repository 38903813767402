import {
  BzDateFns,
  formatMoney,
  isNullish,
  IsoDateString,
  LoanRecord,
  PaymentMethod,
  paymentMethodDisplayName,
  PaymentStatus,
  SharedRoutes,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import LoanDetailsLink from '../Financing/LoanDetailsLink/LoanDetailsLink'
import { InvoiceDetailsLink, JobDetailsLink } from '../Links/EntityLinks'
import PaymentStatusTag from '../Payments/PaymentStatusTag'

export type PaymentCardPayment = {
  paymentRecordGuid: string
  paymentMethod: PaymentMethod
  amountUsd: number
  referenceNumber: string
  status: PaymentStatus
  occuredAt: IsoDateString
  displayId?: number
  jobGuid?: string
  invoiceGuid?: string
  // TODO: Refactor so we are not relying on the full LoanRecord object
  loanRecord?: LoanRecord
}

export interface PaymentCardProps {
  payment: PaymentCardPayment

  /**
   * Should the job link be shown? Defaults to true
   */
  showJobLink?: boolean
}

export const PaymentCard = (props: PaymentCardProps) => {
  const { payment } = props
  const tzId = useExpectedCompanyTimeZoneId()
  const showJobLink = isNullish(props.showJobLink) ? true : props.showJobLink

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link
                to={`${SharedRoutes.PAYMENT_DETAILS.build({
                  params: { paymentRecordGuid: payment.paymentRecordGuid },
                })}`}
                bold
                className="text-sm"
              >
                {formatMoney(payment.amountUsd)} (
                {paymentMethodDisplayName(payment.paymentMethod)})
              </Link>

              <PaymentStatusTag status={payment.status} />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Date',
                BzDateFns.formatFromISO(payment.occuredAt, 'MMM d, yyyy', tzId),
                ...(payment.invoiceGuid
                  ? [
                      'Invoice',
                      <InvoiceDetailsLink
                        guid={payment.invoiceGuid}
                        label={
                          payment.displayId ? `#${payment.displayId}` : 'View'
                        }
                      />,
                    ]
                  : []),
                ...(showJobLink && payment.jobGuid
                  ? ['Job', <JobDetailsLink guid={payment.jobGuid} />]
                  : []),
                ...(payment.loanRecord
                  ? [
                      'Loan Details',
                      // TODO: Refactor LoanDetailsLink so it takes the minimal
                      // amount of data
                      <LoanDetailsLink record={payment.loanRecord} />,
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
