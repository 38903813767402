import { Button } from 'antd'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  PaymentCard,
  PaymentCardPayment,
} from 'src/components/Cards/PaymentCard'

export type PaymentsCollapsibleProps = {
  payments: PaymentCardPayment[]
  total?: number
  mode?: OnsitePageCollapsibleMode
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const PaymentsCollapsible = ({
  mode = 'default',
  ...props
}: PaymentsCollapsibleProps) => {
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Payments"
      count={props.total ?? props.payments.length}
      defaultCollapsed={props.payments.length === 0}
    >
      <div className="flex flex-col gap-3">
        {props.payments.map(payment => (
          <PaymentCard key={payment.paymentRecordGuid} payment={payment} />
        ))}
        {mode === 'paginated' &&
          props.onViewMore &&
          props.total &&
          props.total > props.payments.length && (
            <Button className="w-fit" type="link" onClick={props.onViewMore}>
              View {props.total - props.payments.length} more payments
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
