import { useMemo } from 'react'

import { useCallback } from 'react'

import { PAGINATION_SIZES } from './paginationUtils'

import { useQueryParamStateWithOptions } from '../../utils/react-utils'

import { useQueryParamNumberState } from '../../utils/react-utils'
import { ValidPaginationSize } from './paginationUtils'

export type UsePaginationReturnType = {
  page: number
  pageSize: number
  setPage: (page: number) => void
  setPageSize: (size: ValidPaginationSize) => void
  resetPage: () => void
  limit: number
  offset: number
}

export const usePagination = (
  defaultPageSize: ValidPaginationSize,
  defaultPage?: number,
): UsePaginationReturnType => {
  const [page, setPage] = useQueryParamNumberState('page', defaultPage ?? 0)
  const [pageSize, setPageSizeRaw] = useQueryParamStateWithOptions(
    'pageSize',
    defaultPageSize,
    PAGINATION_SIZES,
  )

  const resetPage = useCallback(() => {
    setPage(defaultPage ?? 0)
  }, [setPage, defaultPage])

  const setPageSize = useCallback(
    (size: ValidPaginationSize) => {
      resetPage()
      setPageSizeRaw(size)
    },
    [resetPage, setPageSizeRaw],
  )

  return useMemo(() => {
    const pageSizeInt = parseInt(pageSize)
    return {
      page,
      pageSize: pageSizeInt,
      setPage,
      setPageSize,
      resetPage,
      // I know that this is the same as "pageSize" above. But it's convenient to alias.
      limit: pageSizeInt,
      offset: page * pageSizeInt,
    }
  }, [page, pageSize, setPage, setPageSize, resetPage])
}
