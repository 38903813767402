import { InstalledEquipmentSummary, isNullish } from '@breezy/shared'
import { useState } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { EquipmentCard } from 'src/components/Cards/EquipmentCard'
import { EquipmentDetailsModal } from 'src/components/EquipmentDetailsModal/EquipmentDetailsModal'

export interface EquipmentCollapsibleProps {
  // TODO: Refactor so this only takes in the minimal amount of data
  equipment: InstalledEquipmentSummary[]
  canManage?: boolean
  onAddEquipment?: () => void
  onEquipmentEdit?: (equipment: InstalledEquipmentSummary) => void
}

export const EquipmentCollapsible = (props: EquipmentCollapsibleProps) => {
  const [openedEquipment, setOpenedEquipment] =
    useState<InstalledEquipmentSummary | null>(null)

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Equipment"
      count={props.equipment.length}
      defaultCollapsed={props.equipment.length === 0}
      onAdd={props.canManage ? props.onAddEquipment : undefined}
      addButtonTestId="equipment-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {props.equipment.map(equipment => (
          <EquipmentCard
            key={equipment.installedEquipmentGuid}
            equipment={equipment}
            onTitleClick={() => setOpenedEquipment(equipment)}
            onEdit={props.canManage ? props.onEquipmentEdit : undefined}
          />
        ))}
      </div>

      {!isNullish(openedEquipment) && (
        <EquipmentDetailsModal
          open={!isNullish(openedEquipment)}
          equipment={openedEquipment}
          onClose={() => setOpenedEquipment(null)}
        />
      )}
    </OnsitePageCollapsibleSection>
  )
}
