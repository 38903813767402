import {
  AddressGuid,
  DateTimeFormatter,
  ENGLISH_LOCALE,
  Location,
} from '@breezy/shared'
import { Button } from 'antd'
import { useMemo } from 'react'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  LocationCard,
  LocationCardLocation,
  LocationCardProps,
} from 'src/components/Cards/LocationCard'

export type LocationsCollapsibleProps = {
  locations: Location[]
  onLocationAdd?: () => void
  onLocationMakeBillingAddress?: (location: Location) => void
  billingAddressGuid?: AddressGuid
  onEdit?: (location: Location) => void
  mode?: OnsitePageCollapsibleMode
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const convertLocationToLocationCardLocation = (
  location: Location,
): LocationCardLocation => {
  return {
    locationGuid: location.locationGuid,
    displayName: location.displayName,
    address: {
      addressGuid: location.address.addressGuid,
      line1: location.address.line1,
      city: location.address.city,
      stateAbbreviation: location.address.stateAbbreviation,
      zipCode: location.address.zipCode,
    },
    yearBuilt: location.estimatedBuildDate
      ? location.estimatedBuildDate?.format(
          DateTimeFormatter.ofPattern('YYYY').withLocale(ENGLISH_LOCALE),
        )
      : undefined,
    squareFootage: location.estimatedSquareFootage,
  }
}

export const LocationsCollapsible = ({
  mode = 'default',
  ...props
}: LocationsCollapsibleProps) => {
  const items: LocationCardProps['location'][] = useMemo(() => {
    const items: LocationCardProps['location'][] = []

    for (const location of props.locations) {
      items.push(convertLocationToLocationCardLocation(location))
    }

    return items
  }, [props.locations])

  return (
    <OnsitePageCollapsibleSection
      title="Locations"
      smallTitle
      count={props.total ?? props.locations.length}
      defaultCollapsed={props.locations.length === 0}
      onAdd={props.onLocationAdd}
      addButtonTestId="locations-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {items.map((location, idx) => (
          <LocationCard
            key={location.locationGuid}
            location={location}
            billingAddressGuid={props.billingAddressGuid}
            onEdit={
              props.onEdit
                ? () => props.onEdit?.(props.locations[idx])
                : undefined
            }
            onMakeBillingAddress={
              props.onLocationMakeBillingAddress
                ? () =>
                    props.onLocationMakeBillingAddress?.(props.locations[idx])
                : undefined
            }
          />
        ))}
        {mode === 'paginated' &&
          props.onViewMore &&
          props.total &&
          props.total > props.locations.length && (
            <Button className="w-fit" type="link" onClick={props.onViewMore}>
              View {props.total - props.locations.length} more locations
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
