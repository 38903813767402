import { z } from 'zod'
import { AsyncFn } from '../../common'
import {
  guidSchema,
  isoDateStringSchema,
  maintenancePlanDiscountBenefitItemSchema,
  maintenancePlanHistoricalPaymentReceivedSchema,
  maintenancePlanPricingSchema,
  maintenancePlanTaxRateSchema,
} from '../../contracts'
import { bzOptional } from '../common-schemas'
import { visitMigrationWritableSchema } from './Visits'

export const maintenancePlanV3ConfigurationDataSchema = z.object({
  /** The Guid of the Maintenance Plan Definition that this plan instance is based on. We should always have one, since companies will need to be able to reference the definition, and likely continue selling the same plan type. */
  maintenancePlanDefinitionGuid: guidSchema,
  numVisitsPerYear: z.number(),
  discounts: maintenancePlanDiscountBenefitItemSchema.array(),
  visits: visitMigrationWritableSchema.array().min(1),

  /** The base yearly price in US Cents for this maintenance plan. Specific pricing will be supplied in the pricing field  */
  yearlyStaticPriceUsc: z.number(),
})

export type MaintenancePlanV3ConfigurationData = z.infer<typeof maintenancePlanV3ConfigurationDataSchema>

export const maintenancePlanV3MigrationInputSchema = z.object({
  // Context
  companyGuid: guidSchema,
  userGuid: guidSchema, // Executing User Guid\

  // Maintenance Plan Core
  maintenancePlanGuid: guidSchema,
  accountGuid: guidSchema,
  locationGuid: guidSchema,

  // Maintenance Plan Configuration
  taxRate: bzOptional(maintenancePlanTaxRateSchema),
  configuration: maintenancePlanV3ConfigurationDataSchema,
  pricing: maintenancePlanPricingSchema,

  // Activation
  activatedAt: isoDateStringSchema,
  /** Number of days until auto cancelation. We presently don't migrate Auto-Renewing plans, so all migrated plans have a fixed duration for now.*/
  numDaysUntilAutoCancelation: z.number(),

  // Historical Payments
  /** (Nice to Have) - This will help preserve the total customer MP revenue for the best data analytics/reporting and customer Value factor calculations */
  historicalPaymentsReceived: maintenancePlanHistoricalPaymentReceivedSchema.array(),
})

export type MaintenancePlanMigrationInputData = z.infer<typeof maintenancePlanV3MigrationInputSchema>
export type MaintenancePlanMigrator = AsyncFn<MaintenancePlanMigrationInputData>
