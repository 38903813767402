import { AccountGuid, Address, LocationGuid } from '@breezy/shared'
import React from 'react'
import { useQuery } from 'urql'
import { GqlQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'

type AvailableLocation = {
  locationGuid: LocationGuid
  displayName?: string | undefined
  address: Address
}

type WithAvailableLocationsProps = {
  accountGuid: AccountGuid
  render: (availableLocations: AvailableLocation[]) => JSX.Element
}

const AVAILABLE_ACCOUNT_LOCATIONS_QUERY = gql(/* GraphQL */ `
  query AvailableAccountLocations($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountLocations {
        location {
          locationGuid
          displayName
          address {
            line1
            line2
            city
            stateAbbreviation
            zipCode
          }
        }
      }
    }
  }
`)

type AvailableAccountLocationsQuery = DocumentType<
  typeof AVAILABLE_ACCOUNT_LOCATIONS_QUERY
>

const convertQueryToAvailableAccountLocations = (
  data: AvailableAccountLocationsQuery,
): AvailableLocation[] => {
  return (
    data?.accountsByPk?.accountLocations.map(accountLocation => ({
      locationGuid: accountLocation.location.locationGuid,
      displayName: accountLocation.location.displayName,
      address: accountLocation.location.address,
    })) ?? []
  )
}

const useFetchAvailableAccountLocations = (accountGuid: AccountGuid) => {
  const availableAccountLocationsQuery = useQuery({
    query: AVAILABLE_ACCOUNT_LOCATIONS_QUERY,
    variables: {
      accountGuid,
    },
  })

  return availableAccountLocationsQuery
}

export const WithAvailableAccountLocations =
  React.memo<WithAvailableLocationsProps>(({ accountGuid, render }) => {
    const availableAccountLocationsQuery =
      useFetchAvailableAccountLocations(accountGuid)

    return (
      <GqlQueryLoader
        query={availableAccountLocationsQuery}
        // We don't want any loading ui since this is wrapping
        // drawers
        loadingComponent={<></>}
        idleComponent={<></>}
        render={data => {
          return render(convertQueryToAvailableAccountLocations(data))
        }}
      />
    )
  })
