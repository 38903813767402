import {
  BzDateFns,
  EquipmentDisplayNames,
  MaintenanceOpportunity,
  metersToMiles,
  Tag as TagType,
  TimeZoneId,
} from '@breezy/shared'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { ContactLink } from '../../../components/ContactLabel/ContactLabel'
import { LocationInfoLinkSimple } from '../../../components/LocationInfoLink'
import { renderMoreTags, Tag } from '../../../components/Tags'
import { AbbreviatedList } from '../../../elements/AbbreviatedList/AbbreviatedList'
import CopyToClipboard from '../../../elements/CopyToClipboard/CopyToClipboard'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'

type MaintenanceOpportunityCardProps = {
  maintenanceOpportunity: MaintenanceOpportunity
  className?: string
  onClick?: () => void
  border?: 'default' | 'active'
}

type MaintenanceOpportunityContextType = {
  maintenanceOpportunity: MaintenanceOpportunity
}
const MaintenanceOpportunityContext = React.createContext<
  MaintenanceOpportunityContextType | undefined
>(undefined)

const useMaintenanceOpportunity = () => {
  const context = useStrictContext(MaintenanceOpportunityContext)
  return context
}

const MaintenanceOpportunityCard = React.memo<
  React.PropsWithChildren<MaintenanceOpportunityCardProps>
>(({ maintenanceOpportunity, onClick, className, children, border }) => {
  const isMaintPlanMember = useMemo(
    () => !!maintenanceOpportunity.maintenancePlan,
    [maintenanceOpportunity],
  )

  const borderStyles = useMemo(() => {
    let borderStyle = ''
    if (!border) {
      borderStyle = 'border-0 border-solid'
    } else {
      borderStyle = 'border border-solid'
    }

    if (isMaintPlanMember) {
      borderStyle = `${borderStyle} border-t-4 border-t-[#1677FF]`
    }

    if (border === 'default') {
      borderStyle = `${borderStyle} border-bz-gray-500`
    } else if (border === 'active') {
      borderStyle = `${borderStyle} border-bz-green-600`
    }

    return borderStyle
  }, [border, isMaintPlanMember])

  const isActive = useMemo(() => border === 'active', [border])
  return (
    <MaintOpportunityWrapper maintenanceOpportunity={maintenanceOpportunity}>
      <div
        className={classNames(
          'flex flex-col space-y-2 p-3 text-bz-gray-900',
          borderStyles,
          className,
        )}
        onClick={onClick}
      >
        {isMaintPlanMember && <MaintOpportunityMaintenancePlanInfo />}
        <MaintOpportunityContactInfo />
        <MaintOpportunityLocationInfo />
        <MaintOpportunityEquipmentInfo isActive={isActive} />
        <MaintOpportunityVisitInfo />
        <MaintOpportunityAccountTags isActive={isActive} />
        <MaintOpportunityDistanceInfo />
        {children}
      </div>
    </MaintOpportunityWrapper>
  )
})

const MaintOpportunityWrapper = React.memo<
  React.PropsWithChildren<MaintenanceOpportunityContextType>
>(({ maintenanceOpportunity, children }) => {
  const providerValue = useMemo(
    () => ({
      maintenanceOpportunity,
    }),
    [maintenanceOpportunity],
  )
  return (
    <MaintenanceOpportunityContext.Provider value={providerValue}>
      {children}
    </MaintenanceOpportunityContext.Provider>
  )
})

const MaintOpportunityContactInfo = React.memo(() => {
  const { maintenanceOpportunity } = useMaintenanceOpportunity()
  const contactPhoneNumberOrEmail = useMemo(
    () =>
      maintenanceOpportunity.primaryContact.phoneNumber ??
      maintenanceOpportunity.primaryContact.emailAddress,
    [
      maintenanceOpportunity.primaryContact.emailAddress,
      maintenanceOpportunity.primaryContact.phoneNumber,
    ],
  )

  return (
    <div className="min-w-full">
      <div className="semibold_14_22 gray9">Primary Contact</div>
      <ContactLink
        className="regular_14_22"
        target="_blank"
        contactFullName={maintenanceOpportunity.primaryContact}
        accountGuid={maintenanceOpportunity.accountGuid}
      />
      {contactPhoneNumberOrEmail && (
        <CopyToClipboard
          payload={contactPhoneNumberOrEmail}
          label={
            <div className="regular_14_22 flex items-center gap-2">
              {contactPhoneNumberOrEmail}
              <FontAwesomeIcon icon={faCopy} />
            </div>
          }
        />
      )}
    </div>
  )
})

const MaintOpportunityLocationInfo = React.memo(() => {
  const { maintenanceOpportunity } = useMaintenanceOpportunity()

  return (
    <div className="min-w-full">
      <LocationInfoLinkSimple
        location={{
          displayName: maintenanceOpportunity.displayName,
          locationGuid: maintenanceOpportunity.locationGuid,
          address: maintenanceOpportunity.address,
        }}
        addressSingleLine
      />
    </div>
  )
})

const MaintOpportunityMaintenancePlanInfo = React.memo(() => {
  const { maintenanceOpportunity } = useMaintenanceOpportunity()

  if (!maintenanceOpportunity.maintenancePlan) return null

  return (
    <div className="min-w-full">
      <div className="flex flex-col">
        <div className="semibold_14_22 gray9">Maintenance Plan</div>
        <BastardizedMaintenancePlanInfoTagList
          maintenanceOpportunity={maintenanceOpportunity}
        />
      </div>
    </div>
  )
})

const MaintOpportunityEquipmentInfo = React.memo<{ isActive?: boolean }>(
  ({ isActive = false }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { maintenanceOpportunity } = useMaintenanceOpportunity()
    return (
      <div className="min-w-full">
        <div className="semibold_14_22 gray9">Equipment</div>
        <div className="regular_14_22 gray9 w-full">
          <BastardizedInstalledEquipmentTagsList
            maintenanceOpportunity={maintenanceOpportunity}
            tzId={tzId}
            isActive={isActive}
          />
        </div>
      </div>
    )
  },
)

const MaintOpportunityVisitInfo = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { maintenanceOpportunity } = useMaintenanceOpportunity()

  return (
    <div className="grid min-w-full grid-cols-2 gap-4">
      {maintenanceOpportunity.lastVisitedAt && (
        <>
          <div>
            <div className="semibold_14_22 gray9">Last Visit Date</div>
            <div className="regular_14_22">
              {BzDateFns.format(
                BzDateFns.parseISO(maintenanceOpportunity.lastVisitedAt, tzId),
                'MMM d, yyyy',
              )}
            </div>
          </div>
        </>
      )}
      <div>
        <div className="semibold_14_22 gray9">Last Maint. Date</div>
        <div className="regular_14_22">
          {maintenanceOpportunity.lastMaintenanceVisitAt
            ? BzDateFns.format(
                BzDateFns.parseISO(
                  maintenanceOpportunity.lastMaintenanceVisitAt,
                  tzId,
                ),
                'MMM d, yyyy',
              )
            : 'N/A'}
        </div>
      </div>
    </div>
  )
})

const MaintOpportunityAccountTags = React.memo<{ isActive?: boolean }>(
  ({ isActive = false }) => {
    const { maintenanceOpportunity } = useMaintenanceOpportunity()

    return (
      <div className="min-w-full">
        <div className="semibold_14_22 gray9">Tags</div>
        <AccountTagsList
          accountTags={maintenanceOpportunity?.accountTags ?? []}
          isActive={isActive}
        />
      </div>
    )
  },
)

const MaintOpportunityDistanceInfo = React.memo(() => {
  const { maintenanceOpportunity } = useMaintenanceOpportunity()
  return (
    <div className="min-w-full">
      <div className="semibold_14_22 gray9">Distance From Assignment</div>
      <div className="regular_14_22">
        {metersToMiles(maintenanceOpportunity.distFromMeters).toFixed(1)} miles
      </div>
    </div>
  )
})

type AccountTagsListProps = {
  accountTags: TagType[]
  isActive?: boolean
}

const AccountTagsList = React.memo<AccountTagsListProps>(
  ({ accountTags, isActive }) => {
    return accountTags.length > 0 ? (
      <AbbreviatedList
        renderMore={renderMoreTags}
        spacingClassName="space-x-1.5"
        backgroundColorClassName={isActive ? 'bg-bz-green-100' : 'bg-white'}
      >
        {accountTags.map(tag => (
          <Tag
            key={tag.name}
            compact
            tagStyleVersion="v2"
            noPopover
            hideIcon
            tag={tag}
            className="mr-0"
          />
        ))}
      </AbbreviatedList>
    ) : (
      <div className="regular_14_22">No tags</div>
    )
  },
)

type BastardizedInstalledEquipmentTagsListProps = {
  maintenanceOpportunity: MaintenanceOpportunity
  tzId: TimeZoneId
  isActive?: boolean
}

const BastardizedInstalledEquipmentTagsList =
  React.memo<BastardizedInstalledEquipmentTagsListProps>(
    ({ maintenanceOpportunity, tzId, isActive = false }) => {
      return maintenanceOpportunity.equipmentMinimal.length > 0 ? (
        <AbbreviatedList
          renderMore={renderMoreTags}
          spacingClassName="space-x-1.5"
          backgroundColorClassName={isActive ? 'bg-bz-green-100' : 'bg-white'}
        >
          {maintenanceOpportunity.equipmentMinimal.map(equipment => {
            return (
              <Tag
                key={equipment.equipmentGuid}
                compact
                tagStyleVersion="v2"
                noPopover
                hideIcon
                tag={{
                  name: `${EquipmentDisplayNames[equipment.equipmentType]}${
                    equipment.installedAt
                      ? (() => {
                          const yearsAgo = BzDateFns.differenceInCalendarYears(
                            BzDateFns.now(tzId),
                            BzDateFns.parseISO(equipment.installedAt, tzId),
                          )
                          if (yearsAgo < 1) return ''
                          return ` (Inst. ${yearsAgo} year${
                            yearsAgo > 1 ? 's' : ''
                          } ago)`
                        })()
                      : ''
                  }`,
                }}
                className="mr-0"
              />
            )
          })}
        </AbbreviatedList>
      ) : (
        <div className="regular_14_22 gray9">No equipment</div>
      )
    },
  )

type BastardizedMaintenancePlanInfoTagListProps = {
  maintenanceOpportunity: MaintenanceOpportunity
}
const BastardizedMaintenancePlanInfoTagList =
  React.memo<BastardizedMaintenancePlanInfoTagListProps>(
    ({ maintenanceOpportunity }) => {
      return maintenanceOpportunity.maintenancePlan ? (
        <AbbreviatedList
          spacingClassName="space-x-1.5"
          backgroundColorClassName=""
        >
          <Tag
            compact
            tagStyleVersion="v2"
            noPopover
            hideIcon
            tag={{
              name: `${maintenanceOpportunity.maintenancePlan.numVisitCreditsPerYear} credits per year`,
            }}
          />
        </AbbreviatedList>
      ) : (
        <div className="regular_14_22 gray9">No equipment</div>
      )
    },
  )

export default MaintenanceOpportunityCard
