import { BzDateFns, isNullish, IsoDateString } from '@breezy/shared'
import { useMemo } from 'react'
import { gql } from 'src/generated'
import useIsMobile from 'src/hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useQuery } from 'urql'
import { JobPhotoAlbumWidgetProps } from './JobPhotoAlbumWidget'
import { PhotoAlbumWidget, PhotoAlbumWidgetProps } from './PhotoAlbumWidget'

const ACCOUNT_PHOTO_ALBUM_WIDGET_QUERY = gql(/* GraphQL */ `
  query AccountPhotoAlbumWidgetQuery($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      photoLinks(orderBy: { updatedAt: DESC }) {
        photo {
          photoGuid
          cdnUrl
          createdByUserGuid
          resourceUrn
          createdAt
        }
        updatedAt
      }
      jobs {
        jobGuid
        displayId
        jobType {
          name
        }
        location {
          locationGuid
          address {
            city
            stateAbbreviation
            zipCode
            line1
          }
        }
        photoLinks(orderBy: { updatedAt: DESC }) {
          photo {
            photoGuid
            cdnUrl
            createdByUserGuid
            resourceUrn
            createdAt
          }
          updatedAt
        }
      }
    }
  }
`)

export const useAccountPhotoAlbumWidgetQuery = (accountGuid: string) =>
  useQuery({
    query: ACCOUNT_PHOTO_ALBUM_WIDGET_QUERY,
    variables: { accountGuid },
  })

export const useAccountPhotoAlbumWidget = (accountGuid: string) => {
  const accountPhotoAlbumWidgetQuery = useQuery({
    query: ACCOUNT_PHOTO_ALBUM_WIDGET_QUERY,
    variables: { accountGuid },
  })

  const accountPhotos = useMemo<
    | (AccountPhotoAlbumWidgetProps['photos'][number] & {
        createdByUserGuid: string
        resourceUrn: string
      })[]
  >(() => {
    const accountPhotoLinks =
      accountPhotoAlbumWidgetQuery[0].data?.accountsByPk?.photoLinks ?? []
    return accountPhotoLinks.map(photo => ({
      photoGuid: photo.photo.photoGuid,
      url: photo.photo.cdnUrl,
      createdAt: photo.photo.createdAt,
      updatedAt: photo.updatedAt,
      createdByUserGuid: photo.photo.createdByUserGuid,
      resourceUrn: photo.photo.resourceUrn,
    }))
  }, [accountPhotoAlbumWidgetQuery])

  const jobPhotos = useMemo<{
    [jobGuid: string]: {
      job: {
        jobGuid: string
        displayId: string
        jobTypeName: string
        location: NonNullable<JobPhotoAlbumWidgetProps['location']>
      }
      photos: (JobPhotoAlbumWidgetProps['photos'][number] & {
        createdByUserGuid: string
        resourceUrn: string
      })[]
    }
  }>(() => {
    const jobPhotosMap = new Map<
      string,
      {
        job: {
          jobGuid: string
          displayId: string
          jobTypeName: string
          location: NonNullable<JobPhotoAlbumWidgetProps['location']>
        }
        photos: (JobPhotoAlbumWidgetProps['photos'][number] & {
          createdByUserGuid: string
          resourceUrn: string
        })[]
      }
    >()
    const accountJobs =
      accountPhotoAlbumWidgetQuery[0].data?.accountsByPk?.jobs ?? []

    for (const job of accountJobs) {
      jobPhotosMap.set(job.jobGuid, {
        job: {
          jobGuid: job.jobGuid,
          displayId: job.displayId.toString(),
          jobTypeName: job.jobType.name,
          location: {
            address: {
              line1: job.location.address.line1,
              city: job.location.address.city,
              stateAbbreviation: job.location.address.stateAbbreviation,
              zipCode: job.location.address.zipCode,
            },
          },
        },
        photos: job.photoLinks.map(photo => ({
          photoGuid: photo.photo.photoGuid,
          url: photo.photo.cdnUrl,
          createdAt: photo.photo.createdAt,
          updatedAt: photo.updatedAt,
          createdByUserGuid: photo.photo.createdByUserGuid,
          resourceUrn: photo.photo.resourceUrn,
        })),
      })
    }

    return Object.fromEntries(jobPhotosMap.entries())
  }, [accountPhotoAlbumWidgetQuery])

  const total = useMemo(() => {
    let total = 0

    if (accountPhotos) {
      total += accountPhotos.length
    }

    if (jobPhotos) {
      total += Object.values(jobPhotos).reduce<number>(
        (acc, curr) => (acc += curr.photos.length),
        0,
      )
    }

    return total
  }, [accountPhotos, jobPhotos])

  return {
    accountPhotoAlbumWidgetQuery,
    refetch: accountPhotoAlbumWidgetQuery[1],
    fetching: accountPhotoAlbumWidgetQuery[0].fetching,
    accountPhotos,
    jobPhotos,
    total,
  }
}

export interface AccountPhotoAlbumWidgetProps {
  photos: PhotoAlbumWidgetProps['photos']
  numPhotos?: number
  title: string
  lastUpdatedAt?: IsoDateString
  options?: {
    thumbnailSize?: 'small' | 'large'
    showLocation?: boolean
  }
  onWidgetClick?: () => void
  onPhotoClick?: (photo: PhotoAlbumWidgetProps['photos'][number]) => void
}

export const AccountPhotoAlbumWidget = (
  props: AccountPhotoAlbumWidgetProps,
) => {
  const timezoneId = useExpectedCompanyTimeZoneId()

  const isMobile = useIsMobile()

  const lastUpdatedAt = !isNullish(props.lastUpdatedAt)
    ? BzDateFns.formatFromISO(props.lastUpdatedAt, 'MMMM dd, yyyy', timezoneId)
    : null

  return (
    <PhotoAlbumWidget photos={props.photos}>
      <div className="flex flex-col">
        <div className="mb-2 flex flex-col gap-[2px]">
          <div className="flex flex-row items-center justify-between">
            <PhotoAlbumWidget.Title
              text={`${props.title}${
                !isNullish(props.numPhotos) ? ' (' + props.numPhotos + ')' : ''
              }`}
            />

            {!isMobile && !isNullish(lastUpdatedAt) && (
              <PhotoAlbumWidget.Subtitle text={`Updated ${lastUpdatedAt}`} />
            )}
          </div>

          {isMobile && !isNullish(lastUpdatedAt) && (
            <PhotoAlbumWidget.Subtitle text={`Updated ${lastUpdatedAt}`} />
          )}
        </div>

        <PhotoAlbumWidget.ThumbnailGrid
          photoDistributionStrategy="balanced"
          onPhotoClick={props.onPhotoClick}
          thumbnailSize={props.options?.thumbnailSize}
        />
      </div>
    </PhotoAlbumWidget>
  )
}
