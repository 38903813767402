import { PermissionV2 } from '@breezy/shared'

import { SharedRoutes } from '@breezy/shared'
import { Navigate } from 'react-router-dom'

import { BehindFeatureFlag } from '../components/BehindFeatureFlag'
import AccountDetailsPageV2 from '../pages/AccountDetailsPageV2/AccountDetailsPageV2'
import { ReactRouteMap } from './ReactRoute'
import { lazyLoad } from './code-splitting'

const noPermissionRequired = undefined

const AccountDetailsPage = lazyLoad(
  () => import('../pages/AccountDetailsPage/AccountDetailsPage'),
)
const JobCreationPage = lazyLoad(
  () => import('../pages/JobCreationPage/JobCreationPage'),
)
const JobDetailsPage = lazyLoad(
  () => import('../pages/JobDetailsPage/JobDetailsPage'),
)
const LocationDetailsPage = lazyLoad(
  () => import('../pages/LocationDetailsPage/LocationDetailsPage'),
)
const MaintenancePlanDetailsPage = lazyLoad(
  () =>
    import('../pages/MaintenancePlanDetailsPage/MaintenancePlanDetailsPage'),
)
const MaintenancePlanPayPage = lazyLoad(
  () => import('../pages/MaintenancePlanPayPage/MaintenancePlanPayPage'),
)
const PaymentDetailsPage = lazyLoad(
  () => import('../pages/PaymentDetailsPage/PaymentDetailsPage'),
)

export const SharedReactRoutes = {
  JOB_CREATION: {
    ...SharedRoutes.JOB_CREATION,
    element: <JobCreationPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  JOB_DETAILS: {
    ...SharedRoutes.JOB_DETAILS,
    element: <JobDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  PAYMENT_DETAILS: {
    ...SharedRoutes.PAYMENT_DETAILS,
    element: <PaymentDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  MAINTENANCE_PLAN_PAYMENT: {
    ...SharedRoutes.MAINTENANCE_PLAN_PAYMENT,
    element: <MaintenancePlanPayPage />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },
  ACCOUNT_DETAILS: {
    ...SharedRoutes.ACCOUNT_DETAILS,
    element: (
      <BehindFeatureFlag
        enabledFeatureFlag="commercial-account-improvements"
        render={<AccountDetailsPageV2 />}
        fallback={<AccountDetailsPage />}
      />
    ),
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  LOCATION_DETAILS: {
    ...SharedRoutes.LOCATION_DETAILS,
    element: <LocationDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
  MAINTENANCE_PLAN_DETAILS: {
    ...SharedRoutes.MAINTENANCE_PLAN_DETAILS,
    element: <MaintenancePlanDetailsPage />,
    permission: PermissionV2.READ_COMPANY_USERS,
    principalUserRequiresCompany: true,
  },
} satisfies ReactRouteMap
