import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { AccountGuid } from '../Accounts/Account'
import { Guid, bzOptional } from '../common-schemas'
import { CompanyGuid, ForCompanyUser } from '../Company/Company'
import { JobGuid } from '../Job'
import { Location, UpsertLocationDTOSchema } from '../Locations/Location'
import { MaintenancePlanGuid } from '../MaintenancePlans/MaintenancePlanTypes'

export type AccountLocation = {
  accountGuid: AccountGuid
  companyGuid: CompanyGuid
  location: Location
  isArchived: boolean
  maintenancePlanGuids?: MaintenancePlanGuid[]
  maintenancePlanVisits?: {
    maintenancePlanGuid: MaintenancePlanGuid
    maintenancePlanVisitGuid: string
    jobGuid?: JobGuid
  }[]
}

export type AccountLocationWithoutMaintenancePlans = Omit<
  AccountLocation,
  'maintenancePlanGuids' | 'maintenancePlanVisits'
>

export const UpsertAccountLocationDTOSchema = z.object({
  accountGuid: guidSchema,
  location: z.union([UpsertLocationDTOSchema, guidSchema]),
  isArchived: z.boolean(),
  skipAddressValidation: bzOptional(z.boolean()),
})

export type UpsertAccountLocationDTO = z.infer<typeof UpsertAccountLocationDTOSchema>

export type IAccountLocationUpserter = AsyncFn<ForCompanyUser<UpsertAccountLocationDTO>>

export type AccountLocationPair = {
  readonly accountGuid: AccountGuid
  readonly locationGuid: Guid
}
