import { AccountGuid } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'

import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import {
  EstimateCard,
  EstimateCardEstimate,
} from '../../../components/Cards/EstimateCard'
import { SimpleEstimateCollapsibleV2 } from '../../../components/collapsibles/EstimatesCollapsible/EstimatesCollapsibleV2'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { gql } from '../../../generated'
import { PaginatedEstimatesCollapsibleQuery } from '../../../generated/user/graphql'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useQueryParamFlag } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const PAGINATED_ESTIMATES_COLLAPSIBLE_QUERY = gql(/* GraphQL */ `
  query PaginatedEstimatesCollapsible(
    $where: EstimatesBoolExp!
    $limit: Int!
    $offset: Int!
  ) {
    estimatesAggregate(where: $where) {
      aggregate {
        count
      }
    }
    estimates(
      where: $where
      orderBy: [{ createdAt: DESC }]
      limit: $limit
      offset: $offset
    ) {
      ...EstimateCollapsibleEstimate
    }
  }
`)

const convertQueryToEstimateCardEstimates = (
  estimates: NonNullable<PaginatedEstimatesCollapsibleQuery['estimates']>,
): EstimateCardEstimate[] => {
  return estimates.map(estimate => ({
    estimateGuid: estimate.estimateGuid,
    displayId: estimate.displayId.toString(),
    issuedAt: estimate.createdAt,
    options: estimate.estimateOptions.map(option => ({
      estimateOptionGuid: option.estimateOptionGuid,
      isSelected: option.isSelected,
      sequence: option.seq,
      totalUsc: option.totalUsc,
      displayName: option.displayName,
    })) satisfies EstimateCardEstimate['options'],
    status: estimate.status,
    createdBy: estimate.createdByUser
      ? { name: estimate.createdByUser.fullName }
      : undefined,
    job: {
      jobGuid: estimate.job.jobGuid,
      displayId: estimate.job.displayId.toString(),
      jobTypeName: estimate.job.jobType.name,
    },
  }))
}

const useFetchAccountEstimates = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountEstimatesQuery = useQuery({
    query: PAGINATED_ESTIMATES_COLLAPSIBLE_QUERY,
    variables: {
      where: {
        job: {
          accountGuid: { _eq: accountGuid },
        },
      },
      limit,
      offset,
    },
  })

  const estimates = useMemo(
    () =>
      convertQueryToEstimateCardEstimates(
        accountEstimatesQuery[0].data?.estimates ?? [],
      ),
    [accountEstimatesQuery],
  )
  const total = useMemo(
    () =>
      accountEstimatesQuery[0].data?.estimatesAggregate?.aggregate?.count ?? 0,
    [accountEstimatesQuery],
  )

  return {
    estimates,
    refetch: accountEstimatesQuery[1],
    fetching: accountEstimatesQuery[0].fetching,
    total,
  }
}

type AccountEstimatesCollapsibleProps = {
  accountGuid: AccountGuid
  onViewMore?: () => void
}

export const AccountEstimatesCollapsible =
  React.memo<AccountEstimatesCollapsibleProps>(
    ({ accountGuid, onViewMore }) => {
      const { estimates, total } = useFetchAccountEstimates(accountGuid, {
        offset: 0,
      })

      const [viewMoreOpen, viewMoreEstimates, closeViewMoreEstimates] =
        useQueryParamFlag('viewMoreEstimates')

      return (
        <>
          <SimpleEstimateCollapsibleV2
            estimates={estimates}
            mode="paginated"
            total={total}
            onViewMore={onViewMore ?? viewMoreEstimates}
          />
          {viewMoreOpen && (
            <AccountEstimatesViewMoreModal
              accountGuid={accountGuid}
              onClose={closeViewMoreEstimates}
            />
          )}
        </>
      )
    },
  )

type AccountEstimatesViewMoreModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
}

const AccountEstimatesViewMoreModal =
  React.memo<AccountEstimatesViewMoreModalProps>(
    ({ accountGuid, onClose: externalOnClose }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
        usePagination('10', 1)

      const { estimates, total, fetching } = useFetchAccountEstimates(
        accountGuid,
        {
          limit,
          offset,
        },
      )

      const onClose = useCallback(() => {
        externalOnClose()
        setPageSize('10')
        resetPage()
      }, [externalOnClose, setPageSize, resetPage])

      return (
        <>
          <OnsiteModal open size="large" onClose={onClose}>
            <OnsiteModalContent
              onClose={onClose}
              header="Estimates"
              footer={
                <PaginationControls
                  className="mt-4"
                  page={page}
                  pageSize={pageSize}
                  totalItems={total}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              }
            >
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <div className="flex min-h-0 flex-col gap-2">
                  {estimates.map(estimate => (
                    <div>
                      <EstimateCard
                        key={estimate.estimateGuid}
                        estimate={estimate}
                        timezoneId={tzId}
                      />
                    </div>
                  ))}
                </div>
              )}
            </OnsiteModalContent>
          </OnsiteModal>
        </>
      )
    },
  )
