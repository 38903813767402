import { AccountGuid, JobClass, JobGuid } from '@breezy/shared'
import React from 'react'
import { useQuery } from 'urql'
import { GqlQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'

type AvailableJob = {
  jobGuid: JobGuid
  displayId: number
  jobType: string
  jobClass: JobClass
  address: {
    line1: string
    line2?: string
    city: string
    stateAbbreviation: string
  }
}

type WithAvailableJobsProps = {
  accountGuid: AccountGuid
  render: (availableJobs: AvailableJob[]) => JSX.Element
}

const AVAILABLE_ACCOUNT_JOBS_QUERY = gql(/* GraphQL */ `
  query AvailableAccountJobs($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      jobs(
        orderBy: [{ displayId: DESC }, { createdAt: DESC }, { updatedAt: DESC }]
      ) {
        jobGuid
        displayId
        jobType {
          name
          jobClass
        }
        location {
          address {
            line1
            line2
            city
            stateAbbreviation
          }
        }
      }
    }
  }
`)

type AvailableAccountJobsQuery = DocumentType<
  typeof AVAILABLE_ACCOUNT_JOBS_QUERY
>

const convertQueryToAvailableAccountJobs = (
  data: AvailableAccountJobsQuery,
): AvailableJob[] => {
  return (
    data?.accountsByPk?.jobs.map(job => ({
      jobGuid: job.jobGuid,
      displayId: job.displayId,
      jobType: job.jobType.name,
      jobClass: job.jobType.jobClass,
      address: {
        line1: job.location.address.line1,
        line2: job.location.address.line2,
        city: job.location.address.city,
        stateAbbreviation: job.location.address.stateAbbreviation,
      },
    })) ?? []
  )
}

const useFetchAvailableAccountJobs = (accountGuid: AccountGuid) => {
  const availableAccountJobsQuery = useQuery({
    query: AVAILABLE_ACCOUNT_JOBS_QUERY,
    variables: {
      accountGuid,
    },
  })

  return availableAccountJobsQuery
}

export const WithAvailableAccountJobs = React.memo<WithAvailableJobsProps>(
  ({ accountGuid, render }) => {
    const availableAccountJobsQuery = useFetchAvailableAccountJobs(accountGuid)

    return (
      <GqlQueryLoader
        query={availableAccountJobsQuery}
        // We don't want any loading ui since this is wrapping
        // drawers
        loadingComponent={<></>}
        idleComponent={<></>}
        render={data => {
          return render(convertQueryToAvailableAccountJobs(data))
        }}
      />
    )
  },
)
