import { BzDateFns } from '@breezy/shared'
import { useEffect, useMemo } from 'react'
import { useMutation } from 'urql'
import { AccountMetadataQuery } from '../../generated/user/graphql'
import { useExpectedPrincipal } from '../../providers/PrincipalUser'
import { UPDATE_VIEWED_AT_TIME_FOR_USER } from '../AccountDetailsPage/AccountDetailsPage.gql'

export const DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT = 10

export type AccountMetadata = NonNullable<AccountMetadataQuery['accountsByPk']>

export const useUpdateViewedAtTimeForUser = (accountGuid: string) => {
  const { userGuid } = useExpectedPrincipal()

  const [, updateViewedAtTimeForUserMutation] = useMutation(
    UPDATE_VIEWED_AT_TIME_FOR_USER,
  )

  useEffect(() => {
    updateViewedAtTimeForUserMutation({
      accountGuid,
      userGuid,
      viewedAt: BzDateFns.nowISOString(),
    })
  }, [accountGuid, updateViewedAtTimeForUserMutation, userGuid])
}

export type Editable<T> = T & {
  editable?: boolean
}

export type AccountWrapper = {
  meta: AccountMetadata
  refetch: () => void
}

type AccountLeadSource = NonNullable<AccountMetadata['accountLeadSource']>

export const useRichCompanyLeadSource = (
  accountLeadSource: AccountLeadSource,
) => {
  return useMemo(() => {
    if (!accountLeadSource.length) {
      return undefined
    }
    const leadSource = accountLeadSource[0]
    return {
      ...leadSource,
      leadSource: {
        name:
          leadSource.companyLeadSource?.canonicalLeadSourceNameOverride ??
          leadSource.companyLeadSource?.canonicalLeadSourceName ??
          'Unknown Lead Source',
      },
    }
  }, [accountLeadSource])
}

type AccountTags = NonNullable<AccountMetadata['tags']>

export const useTags = (accountTags: AccountTags) => {
  return useMemo(() => {
    return accountTags.map(({ tagGuid, tag }) => ({ tagGuid, ...tag }))
  }, [accountTags])
}
