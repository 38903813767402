import { CompanyGuid } from '@breezy/shared'
import React from 'react'

import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

type WithAccountManagerEnabledProps = {
  loadingComponent?: JSX.Element
  render: (accountManagerEnabled: boolean) => JSX.Element
}

const ACCOUNT_MANAGER_ENABLED_QUERY = gql(/* GraphQL */ `
  query AccountManagerEnabled($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      accountManagerEnabled
    }
  }
`)

type AccountManagerEnabledQuery = DocumentType<
  typeof ACCOUNT_MANAGER_ENABLED_QUERY
>

const convertQueryToAccountManagerEnabled = (
  data: AccountManagerEnabledQuery,
): boolean => {
  return data?.companyConfigByPk?.accountManagerEnabled ?? false
}

const useFetchAccountManagerEnabled = (companyGuid: CompanyGuid) => {
  const accountManagerEnabledQuery = useQuery({
    query: ACCOUNT_MANAGER_ENABLED_QUERY,
    variables: {
      companyGuid,
    },
  })

  return accountManagerEnabledQuery
}

export const WithAccountManagerEnabled =
  React.memo<WithAccountManagerEnabledProps>(({ render, loadingComponent }) => {
    const companyGuid = useExpectedCompanyGuid()
    const accountManagerEnabledQuery =
      useFetchAccountManagerEnabled(companyGuid)

    return (
      <GqlQueryLoader
        query={accountManagerEnabledQuery}
        loadingComponent={loadingComponent}
        idleComponent={loadingComponent}
        render={data => {
          return render(convertQueryToAccountManagerEnabled(data))
        }}
      />
    )
  })
