import { AccountGuid } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import { JobCard, JobCardJob } from '../../../components/Cards/JobCard'
import { JobsCollapsible } from '../../../components/collapsibles/JobsCollapsible/JobsCollapsible'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import ProgressiveJobCreationModal from '../../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import { gql } from '../../../generated'
import { AccountJobsCollapsibleV2Query } from '../../../generated/user/graphql'
import { useCanCreateJobs } from '../../../hooks/permission/useCanCreateJob'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useModalState, useQueryParamFlag } from '../../../utils/react-utils'
import { convertAccountDetailsJobInvoiceToJobCardInvoice } from '../../AccountDetailsPage/accountDetailsUtils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const ACCOUNT_JOBS_QUERY = gql(/* GraphQL */ `
  query AccountJobsCollapsibleV2(
    $accountGuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    accountsByPk(accountGuid: $accountGuid) {
      jobsAggregate {
        aggregate {
          count
        }
      }
      jobs(
        orderBy: [{ createdAt: DESC }, { updatedAt: DESC }]
        limit: $limit
        offset: $offset
      ) {
        jobGuid

        jobType {
          jobClass
          name
        }
        displayId
        location {
          locationGuid
          address {
            line1
          }
        }
        createdAt
        jobLifecycleStatus {
          name
          color
        }
        appointments {
          jobAppointmentGuid
          appointmentType
          appointmentWindowStart
          jobInvoices {
            invoice {
              invoiceGuid
              displayId
              totalUsc
              paymentRecords(orderBy: [{ occurredAt: DESC }]) {
                paymentRecordGuid
                paymentMethod
                amountUsd
              }
            }
          }
        }
        createdAt
        completedAt: workCompletedAt
      }
    }
  }
`)

const convertJobtoJobCardJob = (
  job: NonNullable<
    AccountJobsCollapsibleV2Query['accountsByPk']
  >['jobs'][number],
): JobCardJob => ({
  jobGuid: job.jobGuid,
  jobTypeName: job.jobType.name,
  displayId: job.displayId.toString(),
  createdAt: job.createdAt,
  completedAt: job.completedAt,
  jobLifecycleStatus: {
    name: job.jobLifecycleStatus.name,
    color: job.jobLifecycleStatus.color,
  },
  location: {
    locationGuid: job.location.locationGuid,
    address: { line1: job.location.address.line1 },
  },
  tags: [],
  appointments: job.appointments.map(appt => ({
    appointmentGuid: appt.jobAppointmentGuid,
    appointmentType: appt.appointmentType,
    appointmentDate: appt.appointmentWindowStart,
    invoices: appt.jobInvoices.map(invoice =>
      convertAccountDetailsJobInvoiceToJobCardInvoice(invoice),
    ),
  })),
})

const useFetchAccountJobs = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountJobsQuery = useQuery({
    query: ACCOUNT_JOBS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const total = useMemo(() => {
    return (
      accountJobsQuery[0].data?.accountsByPk?.jobsAggregate?.aggregate?.count ??
      0
    )
  }, [accountJobsQuery])

  const jobs = useMemo(
    () =>
      (accountJobsQuery[0].data?.accountsByPk?.jobs ?? []).map(
        convertJobtoJobCardJob,
      ),
    [accountJobsQuery],
  )

  return {
    accountJobsQuery,
    refetch: accountJobsQuery[1],
    fetching: accountJobsQuery[0].fetching,
    total,
    jobs,
  }
}

type AccountJobsCollapsibleProps = {
  accountGuid: AccountGuid
  editable?: boolean
  onViewMore?: () => void
}

export const AccountJobsCollapsible = React.memo<AccountJobsCollapsibleProps>(
  ({ accountGuid, editable = false, onViewMore }) => {
    const timezoneId = useExpectedCompanyTimeZoneId()
    const canCreateNewJobs = useCanCreateJobs()

    const [createNewJobOpen, openCreateNewJob, closeCreateNewJob] =
      useModalState(false)
    const shouldCreateJobQueryParam = useQueryParamFlag('shouldCreateJob')

    const [viewMoreOpen, viewMoreJobs, closeViewMoreJobs] =
      useQueryParamFlag('viewMoreJobs')
    const { jobs, total } = useFetchAccountJobs(accountGuid, { offset: 0 })

    return (
      <>
        <JobsCollapsible
          timezoneId={timezoneId}
          mode="paginated"
          jobs={jobs}
          total={total}
          canManage={editable && canCreateNewJobs}
          onAddJob={openCreateNewJob}
          onViewMore={onViewMore ?? viewMoreJobs}
        />

        {createNewJobOpen && (
          <ProgressiveJobCreationModal
            isOpen={createNewJobOpen || shouldCreateJobQueryParam}
            // BOOOO!!! This prop sucks! This props sucks! - Kendall Roy
            setIsOpen={isOpen => {
              if (!isOpen) {
                closeCreateNewJob()
              } else {
                openCreateNewJob()
              }
            }}
            selectedAccountGuid={accountGuid}
          />
        )}
        {viewMoreOpen && (
          <AccountJobsViewMoreModal
            accountGuid={accountGuid}
            onClose={closeViewMoreJobs}
          />
        )}
      </>
    )
  },
)

type AccountJobsViewMoreModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
}

const AccountJobsViewMoreModal = React.memo<AccountJobsViewMoreModalProps>(
  ({ accountGuid, onClose: externalOnClose }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
      usePagination('10', 1)

    const { jobs, total, fetching } = useFetchAccountJobs(accountGuid, {
      limit,
      offset,
    })

    const onClose = useCallback(() => {
      externalOnClose()
      setPageSize('10')
      resetPage()
    }, [externalOnClose, setPageSize, resetPage])

    return (
      <>
        <OnsiteModal open size="large" onClose={onClose}>
          <OnsiteModalContent
            onClose={onClose}
            header="Jobs"
            footer={
              <PaginationControls
                className="mt-4"
                page={page}
                pageSize={pageSize}
                totalItems={total}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            }
          >
            {fetching ? (
              <LoadingSpinner />
            ) : (
              <div className="flex min-h-0 flex-col gap-2">
                {jobs.map((job, idx) => (
                  <div>
                    <JobCard key={job.jobGuid} job={job} timezoneId={tzId} />
                  </div>
                ))}
              </div>
            )}
          </OnsiteModalContent>
        </OnsiteModal>
      </>
    )
  },
)
