import { isNullish, IsoDateString } from '@breezy/shared'
import classNames from 'classnames'
import {
  createContext,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useId,
  useMemo,
} from 'react'
import { Card } from 'src/adam-components/Card/Card'

interface PhotoAlbumWidgetContextProps {
  photos: {
    photoGuid: string
    url: string
    createdAt?: IsoDateString
    updatedAt?: IsoDateString
  }[]
}

const PhotoAlbumWidgetContext = createContext<PhotoAlbumWidgetContextProps>({
  photos: [],
})

const usePhotoAlbumWidgetContext = () => useContext(PhotoAlbumWidgetContext)

interface PhotoAlbumWidgetTitleProps {
  text: string
  fontSize?: 'text-base' | 'text-sm'
}

const PhotoAlbumWidgetTitle = (props: PhotoAlbumWidgetTitleProps) => {
  const classes = classNames(['font-bold', props.fontSize ?? 'text-base'])

  return <span className={classes}>{props.text}</span>
}

interface PhotoAlbumWidgetSubtitleProps {
  text: string
  fontSize?: 'text-base' | 'text-sm'
}

const PhotoAlbumWidgetSubtitle = (props: PhotoAlbumWidgetSubtitleProps) => {
  const classes = classNames([
    'text-bz-text-secondary',
    props.fontSize ?? 'text-sm',
  ])

  return <span className={classes}>{props.text}</span>
}

interface PhotoAlbumWidgetThumbnailGridProps {
  /**
   * The maximum number of rows in the thumbnail grid.
   */
  maxRows?: number

  /**
   * The maximum number of items that can be in a row in the thumbnail grid. Defaults to 3.
   */
  maxItemsPerRow?: number

  /**
   * Determines how photos should be distributed. Defaults to `auto`.
   *
   *`auto`: Photos are placed into the grid in the order they are received, from left to right.
   *`balanced`: Tries to balance the photos evenly across all the rows.
   */
  photoDistributionStrategy?: 'auto' | 'balanced'

  thumbnailSize?: 'small' | 'large'

  onPhotoClick?: (photo: PhotoAlbumWidgetContextProps['photos'][number]) => void
}

const PhotoAlbumWidgetThumbnailGrid = (
  props: PhotoAlbumWidgetThumbnailGridProps,
) => {
  const { photos } = usePhotoAlbumWidgetContext()

  const thumbnailGridId = useId()

  const distributionStrategy = props.photoDistributionStrategy ?? 'auto'

  const maxRows = props.maxRows ?? 2

  const maxCols = useMemo(() => {
    switch (distributionStrategy) {
      case 'auto':
        return props.maxItemsPerRow ?? 3
      case 'balanced': {
        const totalShownPhotos = !isNullish(props.maxRows)
          ? Math.min(photos.length, (props.maxItemsPerRow ?? 3) * props.maxRows)
          : photos.length

        const remainder = totalShownPhotos % (props.maxItemsPerRow ?? 3)

        if (remainder === 0) {
          return props.maxItemsPerRow ?? 3
        }

        // We add one here to the total items per row to prioritize earlier
        // rows to have more items to give the appearance of a more "balanced"
        // look
        return remainder + 1
      }
      default:
        return props.maxItemsPerRow ?? 3
    }
  }, [distributionStrategy, photos.length, props.maxItemsPerRow, props.maxRows])

  const containerClasses = classNames([
    'w-full',
    'grid',
    'gap-[2px]',
    {
      [`grid-rows-${maxRows}`]: !isNullish(props.maxRows),
    },
  ])

  const rowItems = useMemo(() => {
    const allRowItems: (typeof photos)[number][][] = []
    let currRowItems: (typeof photos)[number][] = []

    const totalShownPhotos = !isNullish(props.maxRows)
      ? Math.min(photos.length, (props.maxItemsPerRow ?? 3) * props.maxRows)
      : photos.length

    for (let i = 0; i < totalShownPhotos; i++) {
      const photo = photos[i]

      currRowItems.push(photo)

      if (currRowItems.length === maxCols) {
        allRowItems.push(currRowItems)
        currRowItems = []
      }
    }

    if (currRowItems.length !== 0) {
      allRowItems.push(currRowItems)
    }

    return allRowItems
  }, [maxCols, photos, props.maxItemsPerRow, props.maxRows])

  return (
    <div
      className={containerClasses}
      // Dynamic grid columns was not working with Tailwind's grid-cols-* class
      style={{
        gridTemplateColumns: `repeat(${maxCols * 2}, minmax(0, 1fr))`,
        gridTemplateRows: !isNullish(props.maxRows)
          ? `repeat(${props.maxRows}, minmax(0, 1fr))`
          : undefined,
      }}
    >
      {rowItems
        .slice(0, !isNullish(props.maxRows) ? props.maxRows : rowItems.length)
        .map((row, idx) => {
          const itemSpan = Math.floor((maxCols * 2) / row.length)

          return (
            <Fragment key={`${thumbnailGridId}Row${idx}`}>
              {row.map((item, colIdx) => {
                const imageClasses = classNames([
                  'h-28',
                  'w-full',
                  'object-cover',
                  {
                    'h-28': (props.thumbnailSize ?? 'small') === 'small',
                    'h-[164px]': (props.thumbnailSize ?? 'small') === 'large',
                    'rounded-tl-md': idx === 0 && colIdx === 0,
                    'rounded-tr-md': idx === 0 && colIdx === row.length - 1,
                    'rounded-bl-md':
                      idx === rowItems.length - 1 && colIdx === 0,
                    'rounded-br-md':
                      idx === rowItems.length - 1 && colIdx === row.length - 1,
                  },
                ])

                return (
                  <div
                    key={item.photoGuid}
                    // Dynamic grid columns was not working with Tailwind's grid-cols-* class
                    style={{
                      gridColumn: `span ${itemSpan} / span ${itemSpan}`,
                    }}
                    onClick={
                      !isNullish(props.onPhotoClick)
                        ? () => props.onPhotoClick?.(item)
                        : undefined
                    }
                    className={classNames({
                      'cursor-pointer': !isNullish(props.onPhotoClick),
                    })}
                  >
                    <img src={item.url} alt="" className={imageClasses} />
                  </div>
                )
              })}
            </Fragment>
          )
        })}
    </div>
  )
}

export interface PhotoAlbumWidgetProps {
  photos: PhotoAlbumWidgetContextProps['photos']
  children?: ReactNode
  onPhotoAlbumWidgetClick?: (photos: PhotoAlbumWidgetProps['photos']) => void
}

export const PhotoAlbumWidget = (props: PhotoAlbumWidgetProps) => {
  const onPhotoAlbumWidgetClick = useCallback(() => {
    if (!props.onPhotoAlbumWidgetClick) {
      return
    }

    props.onPhotoAlbumWidgetClick(props.photos)
  }, [props])

  return (
    <PhotoAlbumWidgetContext.Provider value={{ photos: props.photos }}>
      <Card>
        <div onClick={onPhotoAlbumWidgetClick}>{props.children}</div>
      </Card>
    </PhotoAlbumWidgetContext.Provider>
  )
}

PhotoAlbumWidget.Title = PhotoAlbumWidgetTitle
PhotoAlbumWidget.Subtitle = PhotoAlbumWidgetSubtitle
PhotoAlbumWidget.ThumbnailGrid = PhotoAlbumWidgetThumbnailGrid
