import {
  BzDateFns,
  formatMoney,
  formatUsc,
  IsoDateString,
  PaymentMethod,
  paymentMethodDisplayName,
  TimeZoneId,
} from '@breezy/shared'
import { Tag } from 'antd'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { Link } from 'src/elements/Link/Link'
import { useIsTechnicianApp } from 'src/hooks/useIsTechnicianApp'

export type JobCardJob = {
  jobGuid: string
  jobTypeName: string
  displayId: string
  createdAt: IsoDateString
  jobLifecycleStatus: {
    name: string
    color: string
  }
  location: {
    locationGuid: string
    address: {
      line1: string
    }
  }
  tags: {
    name: string
    color: string
    label?: string
    disabled?: boolean
  }[]
  completedAt?: IsoDateString
  appointments?: {
    appointmentGuid: string
    appointmentType: string
    appointmentDate: IsoDateString
    estimate?: {
      estimateGuid: string
      displayId: string
      totalUsc: number
    }
    invoices?: {
      invoiceGuid: string
      displayId: string
      totalUsc: number
      payments?: {
        paymentRecordGuid: string
        paymentMethod: PaymentMethod
        amountUsd: number
      }[]
    }[]
  }[]
}

export interface JobCardProps {
  job: JobCardJob
  timezoneId: TimeZoneId
}

export const JobCard = (props: JobCardProps) => {
  const { job } = props

  const isTechApp = useIsTechnicianApp()

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link to={`/jobs/${job.jobGuid}`} bold className="text-sm">
                {job.jobTypeName} #{job.displayId}
              </Link>

              <Tag
                style={{
                  color: job.jobLifecycleStatus.color,
                  background: `hsl(from ${job.jobLifecycleStatus.color} h s l / 0.1)`,
                  borderColor: job.jobLifecycleStatus.color,
                }}
              >
                {job.jobLifecycleStatus.name}
              </Tag>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                <Link
                  to={`/locations/${job.location.locationGuid}`}
                  className="text-sm"
                >
                  {job.location.address.line1}
                </Link>,
                'Created Date',
                BzDateFns.formatFromISO(
                  job.createdAt,
                  'MMM dd, yyyy',
                  props.timezoneId,
                ),
                'Completion Date',
                job.completedAt ? (
                  BzDateFns.formatFromISO(
                    job.completedAt,
                    'MMM dd, yyyy',
                    props.timezoneId,
                  )
                ) : (
                  <EmDash />
                ),
              ]}
            />
          ),
        },
        ...(job.appointments
          ? job.appointments.map((appt, idx) => ({
              content: (
                <LabeledItemGrid
                  key={appt.appointmentGuid}
                  items={[
                    `Visit #${idx + 1}`,
                    isTechApp ? (
                      <Link
                        to={`/appointments/${appt.appointmentGuid}`}
                        className="text-sm"
                      >
                        {appt.appointmentType} (
                        {BzDateFns.formatFromISO(
                          appt.appointmentDate,
                          'MMM dd, yyyy',
                          props.timezoneId,
                        )}
                        )
                      </Link>
                    ) : (
                      <span>
                        {appt.appointmentType} (
                        {BzDateFns.formatFromISO(
                          appt.appointmentDate,
                          'MMM dd, yyyy',
                          props.timezoneId,
                        )}
                        )
                      </span>
                    ),
                    ...(appt.estimate
                      ? [
                          'Estimate',
                          <Link to={`/estimates/${appt.estimate.estimateGuid}`}>
                            #{appt.estimate.displayId} (
                            {formatUsc(appt.estimate.totalUsc)})
                          </Link>,
                        ]
                      : []),
                    ...(appt.invoices
                      ? appt.invoices.map(invoice => [
                          'Invoice',
                          <Link
                            key={invoice.invoiceGuid}
                            to={`/invoice/${invoice.invoiceGuid}`}
                          >
                            #{invoice.displayId} ({formatUsc(invoice.totalUsc)})
                          </Link>,
                          ...(invoice.payments
                            ? invoice.payments.map(payment => [
                                'Payment',
                                <Link
                                  key={payment.paymentRecordGuid}
                                  to={`/payments/${payment.paymentRecordGuid}`}
                                >
                                  #
                                  {paymentMethodDisplayName(
                                    payment.paymentMethod,
                                  )}{' '}
                                  ({formatMoney(payment.amountUsd)})
                                </Link>,
                              ])
                            : []
                          ).flat(),
                        ])
                      : []
                    ).flat(),
                  ]}
                />
              ),
            }))
          : []
        ).flat(),
      ]}
    />
  )
}
