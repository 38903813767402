import { createQueryStringFromFields } from '../common'
import { Guid } from '../domain/common-schemas'
import { JOB_APPOINTMENT_GUID_QUERY_PARAM } from './EstimatesContracts'

const createPath = ({ pathname = '/', search = '', hash = '' }) => {
  if (search && search !== '?') pathname += search.charAt(0) === '?' ? search : '?' + search
  if (hash && hash !== '#') pathname += hash.charAt(0) === '#' ? hash : '#' + hash
  return pathname
}

export interface RouteWithCalculate<Path extends string = string> extends Route<Path> {
  calculate(fields: Record<string, unknown>): string
}

export type AnyRoute<Path extends string = string> = Route<Path> | RouteWithCalculate<Path>

const createRouteBuilder = <Path extends string>(spec: Path) => {
  return (routeParams: RouteParams<Path>) => {
    const { params, search } = routeParams
    let routeUrl: string = spec
    const paramEntries = Object.entries(params)
    for (const entry of paramEntries) {
      const [key, value] = entry
      if (typeof value !== 'string') continue

      routeUrl = routeUrl.replace(`:${key}`, value)
    }

    const path = createPath({ pathname: routeUrl, search: search?.toString() })
    return path
  }
}

export function createRoute<Path extends string>(path: Path, config: Omit<Route<Path>, 'path' | 'build'>): Route<Path>
export function createRoute<Path extends string>(
  path: Path,
  config: Omit<RouteWithCalculate<Path>, 'path' | 'build'>,
): RouteWithCalculate<Path>
export function createRoute<Path extends string>(
  path: Path,
  config: Omit<AnyRoute<Path>, 'path' | 'build'>,
): AnyRoute<Path> {
  return {
    ...config,
    path,
    build: createRouteBuilder(path),
  }
}

type SinglePart<Part extends string> = Part extends `:${infer Param}` ? Param : never
type PathParts<Path extends string> = Path extends `${infer A}/${infer B}`
  ? SinglePart<A> | PathParts<B>
  : SinglePart<Path>

export type PathParams<Path extends string> = PathParts<Path> extends never
  ? never
  : {
      [Key in PathParts<Path>]: string
    }

export type RouteParams<Path extends string> = Readonly<{
  params: PathParams<Path>
  search?: URLSearchParams | undefined
}>
export type BuildRouteFn<Path extends string> = (params: RouteParams<Path>) => string

export type Route<Path extends string = string> = {
  path: Path
  label: string
  build: BuildRouteFn<Path>
}

export type GetNewInvoiceQueryProps = {
  jobAppointmentGuid?: string
  invoiceTemplateGuid?: string
}

export const getNewInvoiceQuery = ({ jobAppointmentGuid, invoiceTemplateGuid }: GetNewInvoiceQueryProps) => {
  const searchParams = new URLSearchParams()
  if (jobAppointmentGuid) {
    searchParams.append('appt', jobAppointmentGuid)
  }
  if (invoiceTemplateGuid) {
    searchParams.append('template', invoiceTemplateGuid)
  }
  return searchParams.size ? `?${searchParams.toString()}` : ''
}

type GetInvoiceOverviewQueryProps = {
  present?: boolean
}

const getInvoiceOverviewQuery = ({ present }: GetInvoiceOverviewQueryProps) => {
  const searchParams = new URLSearchParams()
  if (present) {
    searchParams.append('present', '1')
  }
  return searchParams.size ? `?${searchParams.toString()}` : ''
}

type GetEstimateOverviewQueryProps = {
  edit?: boolean
  present?: boolean
}

const getEstimateOverviewQuery = ({ edit, present }: GetEstimateOverviewQueryProps) => {
  const searchParams = new URLSearchParams()
  if (edit) {
    searchParams.append('edit', '1')
  }
  if (present) {
    searchParams.append('p', '1')
  }
  return searchParams.size ? `?${searchParams.toString()}` : ''
}
type GetMPDetailsQueryProps = {
  renew?: boolean
}

const getMPDetailsQuery = ({ renew }: GetMPDetailsQueryProps) => {
  const searchParams = new URLSearchParams()
  if (renew) {
    searchParams.append('renew', '1')
  }
  return searchParams.size ? `?${searchParams.toString()}` : ''
}

export const CalculatePaths = {
  accountDetails: (fields: Record<string, unknown>) => `/accounts/${fields['accountGuid']}`,
  accountDetailsV2: (fields: Record<string, unknown>) => `/accounts-v2/${fields['accountGuid']}`,
  jobDetails: (fields: Record<string, unknown>) => `/jobs/${fields['jobGuid']}`,

  paymentDetails: (fields: Record<string, unknown>) =>
    `/payments/${fields['paymentRecordGuid'] || fields['paymentGuid']}`,

  maintenancePlanActivate: (fields: Record<string, unknown>) => {
    const accountGuid = fields['accountGuid']
    const locationGuid = fields['locationGuid']
    let queryString = ''
    if (accountGuid) queryString += `?accountGuid=${accountGuid}`
    if (accountGuid && locationGuid) queryString += `&locationGuid=${locationGuid}`
    return `/maintenance-plans/activate${queryString}`
  },

  maintenancePlanPay: (fields: Record<string, unknown>) => {
    const updatePaymentMethod = fields['updatePaymentMethod']
    let queryString = ''
    if (updatePaymentMethod) queryString += `?updatePaymentMethod=true`
    return `/maintenance-plans/pay/${fields['maintenancePlanGuid']}${queryString}`
  },

  newInvoiceV2: ({
    accountGuid,
    jobGuid,
    fields,
  }: {
    accountGuid: Guid
    jobGuid: Guid
    fields?: GetNewInvoiceQueryProps
  }) => {
    return `/invoice/new/${accountGuid}/${jobGuid}${getNewInvoiceQuery(fields ?? {})}`
  },

  invoiceOverview: ({ invoiceGuid, ...fields }: Partial<GetInvoiceOverviewQueryProps> & { invoiceGuid: Guid }) =>
    `/invoice/${invoiceGuid}${getInvoiceOverviewQuery(fields ?? {})}`,
  invoiceEdit: ({ invoiceGuid }: { invoiceGuid: Guid }) => `${CalculatePaths.invoiceOverview({ invoiceGuid })}?edit=1`,

  jobCreation: (fields: Record<string, unknown>) => `/job/new${createQueryStringFromFields(fields)}`,

  maintenancePlanDetails: (fields: Record<string, unknown>, flags?: GetMPDetailsQueryProps) =>
    `/maintenance-plans/${fields['maintenancePlanGuid']}${getMPDetailsQuery(flags ?? {})}`,

  locationDetails: (fields: Record<string, unknown>) => `/locations/${fields['locationGuid']}`,

  payoutDetails: (fields: Record<string, unknown>) => `/payouts/${fields['payoutGuid']}`,

  entityHistoryViewer: (fields: Record<string, unknown>) => {
    return `/entity-history/${fields['entityGuid']}`
  },

  estimatesDetails: ({ estimateGuid, ...fields }: Partial<GetEstimateOverviewQueryProps> & { estimateGuid: Guid }) =>
    `/estimates/${estimateGuid}${getEstimateOverviewQuery(fields)}`,
  newEstimate: ({ jobGuid, jobAppointmentGuid }: { jobGuid: Guid; jobAppointmentGuid?: Guid }) =>
    `/jobs/${jobGuid}/new-estimate${
      jobAppointmentGuid ? `?${JOB_APPOINTMENT_GUID_QUERY_PARAM}=${jobAppointmentGuid}` : ''
    }`,

  estimatesEdit: ({ estimateGuid }: { estimateGuid: Guid }) =>
    CalculatePaths.estimatesDetails({ estimateGuid, edit: true }),

  timesheetDetailsForUser: (fields: Record<string, unknown>) => `/timesheets/users/${fields['userGuid']}`,

  appointmentDetails: (fields: Record<string, unknown>) => `/appointments/${fields['appointmentGuid']}`,
  jobRequests: () => `/job-requests`,

  onlineBookingEditServiceType: (fields: Record<string, unknown>) =>
    `/settings/online-booking/edit-service-type/${fields['serviceTypeGuid']}`,
}

export const SharedRoutes = {
  JOB_CREATION: createRoute('/job/new', {
    calculate: CalculatePaths.jobCreation,
    label: 'New Job',
  }),
  ACCOUNT_CREATION: createRoute('/accounts/new', {
    label: 'New Account',
  }),
  NEW_INVOICE_V2: createRoute('/invoice/new/:accountGuid/:jobGuid', {
    calculate: CalculatePaths.newInvoiceV2,
    label: 'Invoice',
  }),
  INVOICE_OVERVIEW: createRoute('/invoice/:invoiceGuid', {
    calculate: CalculatePaths.invoiceOverview,
    label: 'Invoice',
  }),
  JOB_DETAILS: createRoute('/jobs/:jobGuid', {
    calculate: CalculatePaths.jobDetails,
    label: 'Job',
  }),
  PAYMENT_DETAILS: createRoute('/payments/:paymentRecordGuid', {
    label: 'Payment',
  }),
  MAINTENANCE_PLAN_PAYMENT: createRoute('/maintenance-plans/pay/:maintenancePlanGuid', {
    calculate: CalculatePaths.maintenancePlanPay,
    label: 'Maintenance Plan - Pay',
  }),
  ACCOUNT_DETAILS: createRoute('/accounts/:accountGuid', {
    calculate: CalculatePaths.accountDetails,
    label: 'Accounts',
  }),
  LOCATION_DETAILS: createRoute('/locations/:locationGuid', {
    calculate: CalculatePaths.locationDetails,
    label: 'Location',
  }),
  MAINTENANCE_PLAN_DETAILS: createRoute('/maintenance-plans/:maintenancePlanGuid', {
    calculate: CalculatePaths.maintenancePlanDetails,
    label: 'Maintenance Plan',
  }),
  STOP_IMPERSONATING: createRoute('/stop-impersonating', {
    label: 'Stop Impersonating',
  }),
  REFUND_PAYMENT: createRoute('/refund/:paymentRecordGuid', {
    label: 'Refund Payment',
  }),
} as const

export type SharedRouteInfo = typeof SharedRoutes

export const OfficeRoutes = {
  ...SharedRoutes,
  NOT_FOUND: createRoute('*', {
    label: 'Not Found',
  }),
  HOME: createRoute('/', {
    label: 'Dashboard',
  }),
  DASHBOARD: createRoute('/dashboard', {
    label: 'Dashboard',
  }),
  NO_COMPANY: createRoute('/no-company', {
    label: 'No Company',
  }),
  SCHEDULE: createRoute('/schedule', {
    label: 'Schedule',
  }),
  ACCOUNTS: createRoute('/accounts', {
    label: 'Accounts',
  }),
  JOBS_V2: createRoute('/jobs', {
    label: 'Jobs',
  }),
  TECHNICIAN_EXPERIENCE: createRoute('/technician-experience', {
    label: 'Field App',
  }),
  ADMIN: createRoute('/admin', {
    label: 'Admin',
  }),
  USER_DETAILS: createRoute('/users/:userGuid', {
    label: 'Users',
  }),
  INVOICES: createRoute('/invoices', {
    label: 'Invoices',
  }),
  ESTIMATES: createRoute('/estimates', {
    label: 'Estimates',
  }),
  ESTIMATE_V2_DETAILS: createRoute('/estimates/:estimateGuid', {
    label: 'Estimate Details',
  }),
  ESTIMATE_CREATION: createRoute('/jobs/:jobGuid/new-estimate', {
    calculate: CalculatePaths.jobDetails,
    label: 'New Estimate',
  }),
  NOTIFICATIONS: createRoute('/notifications', {
    label: 'Notifications',
  }),
  MAINTENANCE_PLANS: createRoute('/maintenance-plans/', {
    label: 'Maintenance Plans',
  }),
  PAYMENTS: createRoute('/payments', {
    label: 'Payments',
  }),
  ENTITY_HISTORY: createRoute('/entity-history/:entityGuid', {
    label: 'Entity History',
  }),
  PAYOUT_DETAILS: createRoute('/payouts/:payoutGuid', {
    label: 'Payout Details',
  }),
  PAYOUTS: createRoute('/payouts', {
    label: 'Payouts',
  }),
  TIMESHEETS: createRoute('/timesheets', {
    label: 'Timesheets',
  }),
  TIMESHEET_DETAILS: createRoute('/timesheets/users/:userGuid', {
    calculate: CalculatePaths.timesheetDetailsForUser,
    label: 'Timesheet Details',
  }),
  AI_SANDBOX: createRoute('/ai-sandbox', {
    label: 'AI Sandbox',
  }),
  AI_RECOMMENDATION_SANDBOX: createRoute('/ai-sandbox/recommendations', {
    label: 'AI Recommendation Sandbox',
  }),
  ANALYTICS_RECOMMENDATIONS: createRoute('/admin/analytics/recommendations', {
    label: 'Recommendations',
  }),
  SETTINGS: createRoute('/settings/*', {
    label: 'Settings',
  }),
  USER_SETTINGS: createRoute('/settings/user-settings', {
    label: 'User Settings',
  }),
  USER_PROFILE: createRoute('/settings/user-profile', {
    label: 'User Profile',
  }),
  TEAM: createRoute('/settings/team', {
    label: 'Team',
  }),
  CREATE: createRoute('/settings/team/create', {
    label: 'Create Team Member',
  }),
  EDIT: createRoute('/settings/team/edit/:userGuid', {
    label: 'Edit Team Member',
  }),
  PRICEBOOK: createRoute('/settings/pricebook', {
    label: 'Pricebook',
  }),
  MAINTENANCE_PLANS_SETTINGS: createRoute('/settings/maintenance-plans', {
    label: 'Maintenance Plans',
  }),
  COMPANY_WORKING_HOURS: createRoute('/settings/company-working-hours', {
    label: 'Company Hours',
  }),
  COMPANY_CONFIG: createRoute('/settings/company-config', {
    label: 'Config',
  }),
  QUICKBOOKS: createRoute('/settings/quickbooks', {
    label: 'QuickBooks Online',
  }),
  QUICKBOOKS_DESKTOP: createRoute('/settings/quickbooks-desktop', {
    label: 'QuickBooks Desktop',
  }),
  LIFECYCLES: createRoute('/settings/lifecycles', {
    label: 'Job Pipelines',
  }),
  JOB_TYPES: createRoute('/settings/job-types', {
    label: 'Job Types',
  }),
  JOB_REQUESTS_SETTINGS: createRoute('/settings/job-requests', {
    label: 'Job Requests',
  }),
  CHECKLISTS: createRoute('/settings/checklist', {
    label: 'Appointment Checklists',
  }),
  TAGS: createRoute('/settings/tags', {
    label: 'Tags',
  }),
  TIMESHEETS_SETTINGS: createRoute('/settings/timesheets', {
    label: 'Timesheets',
  }),
  TECHNICIAN_PERFORMANCE: createRoute('/settings/technician-performance', {
    label: 'Technician Performance',
  }),
  LEAD_SOURCES: createRoute('/settings/lead-sources', {
    label: 'Lead Sources',
  }),
  BILLING_PROFILE: createRoute('/settings/billing-profile', {
    label: 'Billing Profile',
  }),
  NOTIFICATIONS_SETTINGS: createRoute('/settings/notifications', {
    label: 'Notification Settings',
  }),
  DRIP_MARKETING_SETTINGS: createRoute('/settings/job-follow-up-emails', {
    label: 'Job Follow-up Emails',
  }),
  APPOINTMENT_SETTINGS: createRoute('/settings/appointments', {
    label: 'Visit Settings',
  }),
  BOOKKEEPING: createRoute('/bookkeeping', {
    label: 'Bookkeeping',
  }),
  INTEGRATED_PHONE: createRoute('/comms', {
    label: 'Inbox',
  }),
  JOB_REQUESTS: createRoute('/job-requests', {
    label: 'Job Requests',
  }),
  ASSISTANTS_SETTINGS: createRoute('/settings/assistants', {
    label: 'Assistants',
  }),
  ONLINE_BOOKING_SETTINGS: createRoute('/settings/online-booking', {
    label: 'Online Booking',
  }),
  ONLINE_BOOKING_EDIT_SERVICE_TYPE: createRoute('/settings/online-booking/edit-service-type/:serviceTypeGuid', {
    label: 'Edit Service Type',
  }),
  INVOICE_TEMPLATES: createRoute('/settings/invoice-templates', {
    label: 'Invoice Templates',
  }),
} as const

export type OfficeRouteInfo = typeof OfficeRoutes

export const TechnicianRoutes = {
  ...SharedRoutes,
  NOT_FOUND: createRoute('*', {
    label: 'Not Found',
  }),
  UNAUTHORIZED: createRoute('/unauthorized', {
    label: 'Unauthorized',
  }),
  NO_COMPANY: createRoute('/no-company', {
    label: 'Technician Required',
  }),
  HOME: createRoute('/', {
    label: 'Home',
  }),
  MY_APPOINTMENTS: createRoute('/my-appointments', {
    label: 'My Appointments',
  }),
  APPOINTMENT_DETAILS: createRoute('/appointments/:appointmentGuidOrAppointmentReferenceNumber', {
    label: 'Appointment Details',
  }),
  ACCOUNT_SEARCH: createRoute('/accounts', {
    label: 'Account Search',
  }),
  SCHEDULE: createRoute('/schedule', {
    label: 'Schedule',
  }),
  ESTIMATE_V2_CREATION: createRoute('/jobs/:jobGuid/new-estimate', {
    calculate: CalculatePaths.jobDetails,
    label: 'New Estimate',
  }),
  ESTIMATE_V2_OVERVIEW: createRoute('/estimates/:estimateGuid', {
    calculate: CalculatePaths.estimatesDetails,
    label: 'Estimate',
  }),
  NOTIFICATIONS: createRoute('/notifications', {
    label: 'Notifications',
  }),
} as const

export type TechnicianRouteInfo = typeof TechnicianRoutes

export type ParamsFromRoute<R> = R extends Route<infer P> ? RouteParams<P> : never
