import { Button, Dropdown, Pagination } from 'antd'
import classNames from 'classnames'
import './PaginationControls.less'
import { PAGINATION_SIZES, ValidPaginationSize } from './paginationUtils'

type PaginationControlsProps = {
  page: number
  pageSize: number
  totalItems: number
  setPage: (page: number) => void
  setPageSize: (size: ValidPaginationSize) => void
  className?: string
}

export const PaginationControls = ({
  page,
  pageSize,
  totalItems,
  setPage,
  setPageSize,
  className,
}: PaginationControlsProps) => (
  <div
    className={classNames(
      'flex flex-row justify-end overflow-hidden',
      className,
    )}
  >
    <Pagination
      showSizeChanger={false}
      current={page + 1}
      pageSize={pageSize}
      total={totalItems}
      onChange={page => setPage(page - 1)}
    />
    <Dropdown
      autoAdjustOverflow
      overlayClassName="pagination-controls-dropdown"
      menu={{
        selectable: true,
        onClick: ({ key }) => {
          setPageSize(key as ValidPaginationSize)
          setPage(0)
        },
        selectedKeys: [`${pageSize}`],
        items: PAGINATION_SIZES.map(size => ({
          key: size,
          label: size,
        })),
      }}
    >
      <Button>{pageSize} / Page</Button>
    </Dropdown>
  </div>
)
