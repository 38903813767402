import { JobClass } from '../Job/JobClass'
import { JobType } from './JobTypes'

type DefaultJobType = Omit<
  JobType,
  | 'jobTypeGuid'
  | 'jobLifecycleGuid'
  | 'companyGuid'
  | 'lastModifiedBy'
  | 'createdAt'
  | 'updatedAt'
  | 'isOpportunity'
  | 'opportunityConversionThresholdUsc'
  | 'isPotentialHotLead'
  | 'hotLeadMinimumEquipmentAgeYears'
>

export const DEFAULT_SERVICE_JOB_TYPES = [
  {
    name: 'No Cold Air',
    description: 'A/C system not cooling.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
  {
    name: 'No Hot Air',
    description: 'Heating system not warming.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
  {
    name: 'A/C Leaking Water',
    description: 'Water leak from A/C unit.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
  {
    name: 'Thermostat Issue',
    description: 'Malfunctioning thermostat.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
  {
    name: 'Equipment Repair',
    description: 'HVAC equipment malfunction.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
  {
    name: 'Miscellaneous Service',
    description: 'Other HVAC-related issues.',
    jobClass: JobClass.SERVICE,
    durationMinutes: 120,
  },
] as const satisfies DefaultJobType[]

export const DEFAULT_MAINTENANCE_JOB_TYPES = [
  {
    name: 'Furnace Tune-up',
    description: 'Routine furnace maintenance.',
    jobClass: JobClass.MAINTENANCE,
    durationMinutes: 90,
  },
  {
    name: 'A/C Tune-up',
    description: 'Routine A/C maintenance.',
    jobClass: JobClass.MAINTENANCE,
    durationMinutes: 90,
  },
  {
    name: 'Filter Change',
    description: 'HVAC filter replacement.',
    jobClass: JobClass.MAINTENANCE,
    durationMinutes: 90,
  },
  {
    name: 'Duct Cleaning',
    description: 'Cleaning of air ducts.',
    jobClass: JobClass.MAINTENANCE,
    durationMinutes: 90,
  },
  {
    name: 'Miscellaneous Maintenance',
    description: 'Other maintenance tasks.',
    jobClass: JobClass.MAINTENANCE,
    durationMinutes: 90,
  },
] as const satisfies DefaultJobType[]

export const DEFAULT_INSTALL_JOB_TYPES = [
  {
    name: 'A/C Install',
    description: 'New A/C unit installation.',
    jobClass: JobClass.INSTALL,
    durationMinutes: 480,
  },
  {
    name: 'Furnace Install',
    description: 'New furnace installation.',
    jobClass: JobClass.INSTALL,
    durationMinutes: 480,
  },
  {
    name: 'A/C & Furnace Install',
    description: 'Combined A/C and furnace installation.',
    jobClass: JobClass.INSTALL,
    durationMinutes: 480,
  },
  {
    name: 'IAQ Install',
    description: 'Indoor Air Quality equipment installation.',
    jobClass: JobClass.INSTALL,
    durationMinutes: 480,
  },
  {
    name: 'Miscellaneous Install',
    description: 'Other installation tasks.',
    jobClass: JobClass.INSTALL,
    durationMinutes: 480,
  },
] as const satisfies DefaultJobType[]

export const DEFAULT_SALES_JOB_TYPES = [
  {
    name: 'Heating System Estimate',
    description: 'Estimate for a new heating system',
    jobClass: JobClass.SALES,
    durationMinutes: 240,
  },
  {
    name: 'Cooling System Estimate',
    description: 'Estimate for a new cooling system',
    jobClass: JobClass.SALES,
    durationMinutes: 240,
  },
  {
    name: 'Full System Estimate',
    description: 'Estimate for a new heating and cooling system',
    jobClass: JobClass.SALES,
    durationMinutes: 240,
  },
  {
    name: 'Other Estimate',
    description: 'Get an estimate for something else',
    jobClass: JobClass.SALES,
    durationMinutes: 240,
  },
] as const satisfies DefaultJobType[]

export const DEFAULT_JOB_TYPES = [
  ...DEFAULT_SERVICE_JOB_TYPES,
  ...DEFAULT_MAINTENANCE_JOB_TYPES,
  ...DEFAULT_INSTALL_JOB_TYPES,
  ...DEFAULT_SALES_JOB_TYPES,
] as const

export type DefaultJobTypeNames = (typeof DEFAULT_JOB_TYPES)[number]['name']
export type DefaultServiceJobTypeNames = (typeof DEFAULT_SERVICE_JOB_TYPES)[number]['name']
export type DefaultMaintenanceJobTypeNames = (typeof DEFAULT_MAINTENANCE_JOB_TYPES)[number]['name']
export type DefaultInstallJobTypeNames = (typeof DEFAULT_INSTALL_JOB_TYPES)[number]['name']
export type DefaultSalesJobTypeNames = (typeof DEFAULT_SALES_JOB_TYPES)[number]['name']
