import {
  IconDefinition,
  faPrintMagnifyingGlass,
  faReceipt,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useScrollbarWidth } from 'react-use'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { FlexItem, calculateFlex } from '../../utils/layoutUtils'
import { typedMemo } from '../../utils/react-utils'
import { PaginationControls } from '../Pagination/PaginationControls'
import { ValidPaginationSize } from '../Pagination/paginationUtils'
import { FilterHeightContext } from './ListPageContainer'
import './ListPageTable.less'

export type ListPageTableColsConfig<T> = (Partial<FlexItem> & {
  header: string
  cellClassName?: string
  render: (row: T) => React.ReactNode
})[]

type ListPageTableProps<T> = {
  data?: T[]
  fetching: boolean
  cols: ListPageTableColsConfig<T>
  rowKey: keyof T
  page: number
  setPage: (page: number) => void
  pageSize: number
  setPageSize: (pageSize: ValidPaginationSize) => void
  totalItems: number
  hasFilters?: boolean
  noResultsIcon?: IconDefinition
  itemsDescriptor: string
  renderRowWrapper?: (data: T, children: React.ReactNode) => React.ReactNode
  autoSize?: boolean
}

export const ListPageTable = typedMemo(
  <T,>({
    data,
    cols,
    fetching,
    rowKey,
    page,
    pageSize,
    totalItems,
    setPage,
    setPageSize,
    hasFilters,
    noResultsIcon = faReceipt,
    itemsDescriptor,
    renderRowWrapper,
    autoSize,
  }: ListPageTableProps<T>) => {
    const scrollBarWidth = useScrollbarWidth()

    const containerRef = useRef<HTMLDivElement>(null)

    const [tableWidth, setTableWidth] = useState(0)

    const onResize = useCallback<OnResize>(({ width }) => {
      setTableWidth(width)
    }, [])

    useResizeObserver(containerRef, onResize)

    const [widths, totalMinWidth] = useMemo(
      () =>
        calculateFlex(
          cols.map(({ flex = 0, minWidth = 100 }) => ({ flex, minWidth })),
          tableWidth,
        ),
      [cols, tableWidth],
    )

    const filterHeight = useContext(FilterHeightContext)

    return (
      <div
        ref={containerRef}
        className="relative mr-[-24px] flex-1 pb-6"
        style={{
          scrollbarGutter: 'stable',
          paddingRight: `${24 - (scrollBarWidth ?? 0)}px`,
        }}
      >
        {data &&
          (data.length ? (
            <>
              <table
                className={classNames(
                  'w-full max-w-full',
                  autoSize ? 'table-auto' : 'table-fixed',
                )}
                style={{ minWidth: `${totalMinWidth}px` }}
              >
                <thead
                  className="sticky z-10"
                  style={{ top: `${(filterHeight ?? 0) + 24}px` }}
                >
                  <tr className="bg-white">
                    {cols.map(({ header }, i) => (
                      <th
                        key={header}
                        className="overflow-hidden border-0 border-b border-solid border-transparent"
                        style={{
                          width: autoSize ? undefined : `${widths[i]}px`,
                        }}
                      >
                        <div className="whitespace-nowrap border-0 border-b border-solid border-b-bz-gray-400 px-4 pb-4 text-left text-sm font-semibold uppercase text-bz-gray-800">
                          {header}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map(datum => {
                    const row = (
                      <tr
                        className="border-0 border-b border-t border-solid border-b-bz-gray-400 *:px-4"
                        key={`${datum[rowKey]}`}
                      >
                        {cols.map(({ header, render, cellClassName }) => (
                          <td
                            key={header}
                            className={classNames(
                              'overflow-hidden',
                              cellClassName,
                            )}
                          >
                            {render(datum)}
                          </td>
                        ))}
                      </tr>
                    )
                    if (renderRowWrapper) {
                      return (
                        <React.Fragment key={`${datum[rowKey]}`}>
                          {renderRowWrapper(datum, row)}
                        </React.Fragment>
                      )
                    }
                    return row
                  })}
                </tbody>
              </table>
              <PaginationControls
                className="mt-4"
                page={page}
                pageSize={pageSize}
                totalItems={totalItems}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </>
          ) : (
            <div className="flex flex-col items-center rounded-md bg-bz-gray-200 pb-24 pt-16">
              <div className="list-page-table-empty-state-circle-icon-drop-shadow flex h-14 w-14 items-center justify-center rounded-full bg-bz-gray-100">
                <FontAwesomeIcon
                  icon={hasFilters ? faPrintMagnifyingGlass : noResultsIcon}
                  className="text-2xl text-daybreak-blue-400"
                />
              </div>
              <div className="mb-2 mt-4 text-xl font-semibold text-bz-gray-900">
                No {hasFilters ? 'results' : itemsDescriptor}
              </div>
              <div className="text-bz-gray-900">
                {hasFilters
                  ? `No ${itemsDescriptor} match that criteria. Please try again.`
                  : `${itemsDescriptor
                      .charAt(0)
                      .toUpperCase()}${itemsDescriptor.substring(
                      1,
                    )} will be displayed here.`}
              </div>
            </div>
          ))}

        {fetching && (
          <div className="absolute inset-0 z-10 flex items-center justify-center backdrop-blur-sm">
            <LoadingSpinner />
          </div>
        )}
      </div>
    )
  },
)
