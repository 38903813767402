import {
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  isNullish,
} from '@breezy/shared'
import { useState } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { HvacSystemCard } from 'src/components/Cards/HvacSystemCard'
import { EquipmentDetailsModal } from 'src/components/EquipmentDetailsModal/EquipmentDetailsModal'

export interface HvacSystemsCollapsibleProps {
  hvacSystems: InstalledHvacSystem[]
  canManage?: boolean
  onHvacSystemAdd?: () => void
  onHvacSystemEdit?: (hvacSystem: InstalledHvacSystem) => void
  onEquipmentEdit?: (equipment: InstalledEquipmentSummary) => void
}

export const HvacSystemsCollapsible = (props: HvacSystemsCollapsibleProps) => {
  const { hvacSystems } = props

  const [openedEquipment, setOpenedEquipment] =
    useState<InstalledEquipmentSummary | null>(null)

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="HVAC Systems"
      count={hvacSystems.length}
      defaultCollapsed={hvacSystems.length === 0}
      onAdd={props.canManage ? props.onHvacSystemAdd : undefined}
      addButtonTestId="hvac-systems-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {hvacSystems.map(hvacSystem => (
          <HvacSystemCard
            key={hvacSystem.installedHvacSystemGuid}
            hvacSystem={hvacSystem}
            onHvacSystemEdit={
              props.canManage ? props.onHvacSystemEdit : undefined
            }
            onEquipmentEdit={
              props.canManage ? props.onEquipmentEdit : undefined
            }
            onEquipmentView={equipment => setOpenedEquipment(equipment)}
          />
        ))}
      </div>

      {!isNullish(openedEquipment) && (
        <EquipmentDetailsModal
          open={!isNullish(openedEquipment)}
          equipment={openedEquipment}
          onClose={() => setOpenedEquipment(null)}
        />
      )}
    </OnsitePageCollapsibleSection>
  )
}
