import { AccountGuid } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import MaintenancePlansCollapsible from '../../../components/collapsibles/MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import { gql } from '../../../generated'
import { convertMaintenancePlanToCollapsibleData } from '../../AccountDetailsPage/accountDetailsUtils'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'

const ACCOUNT_MAINTENANCE_PLANS_COLLAPSIBLE_QUERY = gql(/* GraphQL */ `
  query AccountMaintenancePlansCollapsible($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      maintenancePlansAggregate {
        aggregate {
          count
        }
      }
      maintenancePlans {
        ...MaintenancePlanCollapsibleData
      }
    }
  }
`)

const useFetchAccountMaintenancePlans = (accountGuid: AccountGuid) => {
  const accountMaintenancePlansQuery = useQuery({
    query: ACCOUNT_MAINTENANCE_PLANS_COLLAPSIBLE_QUERY,
    variables: {
      accountGuid,
    },
  })

  const total = useMemo(() => {
    return (
      accountMaintenancePlansQuery[0].data?.accountsByPk
        ?.maintenancePlansAggregate?.aggregate?.count ?? 0
    )
  }, [accountMaintenancePlansQuery])

  const maintenancePlans = useMemo(() => {
    return convertMaintenancePlanToCollapsibleData(
      accountMaintenancePlansQuery[0].data?.accountsByPk?.maintenancePlans ??
        [],
    )
  }, [accountMaintenancePlansQuery])

  return {
    accountMaintenancePlansQuery,
    refetch: accountMaintenancePlansQuery[1],
    fetching: accountMaintenancePlansQuery[0].fetching,
    total,
    maintenancePlans,
  }
}

type AccountMaintenancePlansCollapsibleProps = {
  accountGuid: AccountGuid
}

export const AccountMaintenancePlansCollapsible =
  React.memo<AccountMaintenancePlansCollapsibleProps>(({ accountGuid }) => {
    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('mpw', 'account-details')

    const { maintenancePlans, refetch: refetchMaintenancePlans } =
      useFetchAccountMaintenancePlans(accountGuid)

    const onClose = useCallback(() => {
      closeMaintenancePlanWizard()
      refetchMaintenancePlans()
    }, [closeMaintenancePlanWizard, refetchMaintenancePlans])

    return (
      <>
        <MaintenancePlansCollapsible
          plans={maintenancePlans}
          createNew={() => {
            openMaintenancePlanWizard()
          }}
        />
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="account-details"
            accountGuid={accountGuid}
            onClose={onClose}
          />
        )}
      </>
    )
  })
