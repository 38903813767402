import {
  BzAddress,
  BzDateFns,
  formatName,
  getDisplayNameForAccountType,
} from '@breezy/shared'
import {
  faEdit,
  faEllipsis,
  faHistory,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Dropdown, Row } from 'antd'
import React, { useCallback } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { QuickbooksSyncAccountButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksSyncButton'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import { CardOnFileCollapsibleLoader } from '../../components/collapsibles/CardOnFileCollapsible/CardOnFileCollapsible'
import { useFinancingWizard } from '../../components/Financing/hooks/useFinancingWizard'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { RichCompanyLeadSourceView } from '../../components/RichCompanyLeadSourceView/RichCompanyLeadSourceView'
import { TagList } from '../../components/Tags'
import { ServiceHistoryModal } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/ServiceHistory/ServiceHistory'
import BzColumn from '../../elements/BzColumn/BzColumn'
import { EmDash } from '../../elements/EmDash/EmDash'
import { trpc } from '../../hooks/trpc'
import {
  useQuickbooksOnlineEnabled,
  useWisetackEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useModalState, useQueryParamFlag } from '../../utils/react-utils'
import {
  ArchiveAccountModal,
  useArchiveAccountModal,
} from '../AccountDetailsPage/ArchiveAccountModal'
import {
  AccountWrapper,
  Editable,
  useRichCompanyLeadSource,
  useTags,
} from './accountDetailsV2Utils'
import { AccountContactsCollapsible } from './collapsibles/AccountContactsCollapsible'
import { AccountEquipmentAndHvacSystemCollapsible } from './collapsibles/AccountEquipmentAndHvacSystemCollapsible'
import { AccountLocationsCollapsible } from './collapsibles/AccountLocationsCollapsible'
import { EditAccountDrawer } from './EditAccountDrawer'
import { WithAccountManagerEnabled } from './WithAccountManagerEnabled'

export const AccountDetailsLeftPane = React.memo<Editable<AccountWrapper>>(
  ({ meta, refetch: providedRefetch, editable = true }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      accountGuid,
      accountDisplayName,
      archived,
      mailingAddress,
      accountType,
      accountManager,
      accountCreatedAt,
      accountLeadSource,
      tags: accountTags,
    } = meta
    const wisetackEnabled = useWisetackEnabled()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
    const [editAccountDrawerOpen, startEditAccount, stopEditAccount] =
      useQueryParamFlag('edit')

    const { archiveAccount, closeConfirmProps } = useArchiveAccountModal(
      accountGuid,
      archived,
    )
    const leadSource = useRichCompanyLeadSource(accountLeadSource)
    const tags = useTags(accountTags)

    const [
      serviceHistoryModalOpen,
      openServiceHistoryModal,
      closeServiceHistoryModal,
    ] = useModalState()

    const qboStaleAccountQuery = trpc.qbo[
      'finance-app:get-stale-accounts'
    ].useQuery(
      {
        accountGuid,
      },
      {
        enabled: isQuickbooksOnlineEnabled,
      },
    )

    const refetch = useCallback(() => {
      providedRefetch()

      if (isQuickbooksOnlineEnabled) {
        qboStaleAccountQuery.refetch()
      }
    }, [providedRefetch, qboStaleAccountQuery, isQuickbooksOnlineEnabled])

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      onCancel: refetch,
    })

    return (
      <SectionedCard
        accentBarColor={archived ? '#8C8C8C' : '#0958D9'}
        accentBarBody={
          archived ? (
            <span className="self-center text-white">Archived</span>
          ) : undefined
        }
        sections={[
          {
            content: (
              <>
                <BzColumn noPadding>
                  <div className="flex items-center justify-between">
                    <div className="text-xl font-semibold leading-7">
                      {accountDisplayName}
                    </div>

                    <div className="row flex items-center space-x-2">
                      {editable && isQuickbooksOnlineEnabled && (
                        <QuickbooksSyncAccountButton
                          loading={qboStaleAccountQuery.isLoading}
                          staleInfo={
                            qboStaleAccountQuery.data?.[meta.accountGuid]
                          }
                          params={{ accountGuid }}
                          className="ml-2"
                          onSuccess={qboStaleAccountQuery.refetch}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-3 flex flex-row flex-wrap space-x-2">
                    {editable && (
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={startEditAccount}
                        data-testid="edit-account-button"
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      type="default"
                      icon={<FontAwesomeIcon icon={faHistory} />}
                      onClick={openServiceHistoryModal}
                      data-dd-action-name="bz-service-history-account-details"
                    >
                      Service History
                    </Button>

                    {editable && (
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: [
                            ...(wisetackEnabled
                              ? [
                                  {
                                    key: 'Send Financing',
                                    label: 'Send Financing',
                                    onClick: () => showFinancingWizard(),
                                  },
                                ]
                              : []),
                            {
                              key: 'Archive Account',
                              label: archived
                                ? 'Unarchive Account'
                                : 'Archive Account',
                              danger: !archived,
                              onClick: archiveAccount,
                            },
                          ],
                        }}
                      >
                        <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                      </Dropdown>
                    )}
                  </div>
                  <div className="flex justify-between space-x-1">
                    <VerticalKeyValue
                      pair={{
                        key: 'Account Type',
                        value: getDisplayNameForAccountType(accountType),
                      }}
                    />
                    {accountManager && (
                      <WithAccountManagerEnabled
                        render={accountManagerEnabled => {
                          return accountManagerEnabled ? (
                            <VerticalKeyValue
                              pair={{
                                key: 'Account Manager',
                                value: formatName(accountManager),
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }}
                      />
                    )}
                    <VerticalKeyValue
                      pair={{
                        key: 'Created On',
                        value: BzDateFns.formatFromISO(
                          accountCreatedAt,
                          'MMM d, yyyy',
                          tzId,
                        ),
                      }}
                    />
                    <VerticalKeyValue
                      pair={{
                        key: 'Lead Source',
                        value: leadSource ? (
                          <RichCompanyLeadSourceView
                            accountGuid={accountGuid}
                            leadSource={leadSource}
                          />
                        ) : (
                          <EmDash />
                        ),
                      }}
                    />
                  </div>
                  {mailingAddress && (
                    <Row>
                      <Col span={24}>
                        <div className="mt-5">
                          <h4 className="gray9">Billing Address</h4>
                          <AddressMultiLineView
                            address={BzAddress.create(mailingAddress)}
                            labelClassName="semibold_14_22"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div className="flex flex-col space-y-3">
                    <span className="font-semibold text-bz-gray-900">Tags</span>
                    <TagList tags={tags} spacingY={2} />
                  </div>

                  <Divider />

                  <AccountContactsCollapsible
                    accountGuid={accountGuid}
                    editable={editable}
                  />

                  <AccountLocationsCollapsible
                    accountGuid={accountGuid}
                    editable={editable}
                  />

                  <CardOnFileCollapsibleLoader
                    accountGuid={accountGuid}
                    editable={editable}
                  />

                  <AccountEquipmentAndHvacSystemCollapsible
                    accountGuid={accountGuid}
                    editable={editable}
                  />
                </BzColumn>

                <EditAccountDrawer
                  meta={meta}
                  open={editAccountDrawerOpen}
                  onClose={stopEditAccount}
                  refetch={refetch}
                />
                {serviceHistoryModalOpen && (
                  <ServiceHistoryModal
                    accountGuid={accountGuid}
                    onClose={closeServiceHistoryModal}
                  />
                )}

                {financingWizard}

                <ArchiveAccountModal
                  {...closeConfirmProps}
                  archived={archived}
                />
              </>
            ),
          },
        ]}
      />
    )
  },
)
