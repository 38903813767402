import { z } from 'zod'
import { AsyncFn, isNullish, IsoDateString, LocalDate, LocalDateString, nextGuid, R } from '../../common'
import { BzDateFns, TimeZoneId } from '../../common/BzDateFns'
import { guidSchema, isoDateStringSchema, visitRequestSchema } from '../../contracts'
import { defaultAffinityMonthsNumbersJs, getAffinityDateByVisitIndex } from '../AffinityDate/AffinityDate'
import { Guid } from '../common-schemas'
import { InstalledEquipment } from '../InstalledEquipment/InstalledEquipment'
import { JobGuidContainer } from '../Job'
import { SpecialLifecycleStatus } from '../JobLifecycles/JobLifecycles'

export const VISIT_STATUS_TYPES = ['UNSCHEDULED', 'OVERDUE', 'SCHEDULED', 'COMPLETED', 'EXPIRED'] as const

export type VisitStatus = (typeof VISIT_STATUS_TYPES)[number]
export const VisitStatusDisplayNames: Record<VisitStatus, string> = {
  UNSCHEDULED: 'Unscheduled',
  OVERDUE: 'Overdue',
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
}

export type VisitEquipmentViewModel = {
  installedEquipmentGuid: Guid
  equipmentType: string
  manufacturer?: string
  installationDate?: LocalDate
  estimatedEndOfLifeDate?: LocalDate
  averageLifeExpectancyYears?: number
  modelNumber?: string
  serialNumber?: string
}

export type VisitJobViewModel = {
  jobGuid: Guid
  jobTypeName: string
  jobDisplayId: number
  workCompletedAt?: IsoDateString
}

export type VisitDates = {
  issuedAt: IsoDateString
  expiresAt: IsoDateString
  dueAt: IsoDateString
}

export type VisitViewModel = {
  maintenancePlanGuid: Guid
  companyGuid: Guid
  visitGuid: Guid
  name: string
  status: VisitStatus
  affinityDate: LocalDateString
  visitJob?: VisitJobViewModel
  visitEquipment: VisitEquipmentViewModel[]
  isExpiredOverride?: boolean
  isCompletedOverride?: boolean
  completedAtOverride?: IsoDateString
  oldestEquipmentAgeYears?: number

  issuedAt: IsoDateString
  expiresAt: IsoDateString
  dueAt: IsoDateString
}

export const getVisitLabel = (visit: VisitViewModel & { index: number }): string => {
  const concatenatedCoveredEquipmentType = visit.visitEquipment
    .map(ve => InstalledEquipment.calculateFriendlyName(ve))
    .join(', ')
  const label = `Visit #${visit.index + 1} (${visit.name}) ${
    concatenatedCoveredEquipmentType ? `— ${concatenatedCoveredEquipmentType}` : ''
  }`

  return label
}

export type VisitRequest = z.infer<typeof visitRequestSchema>

export type VisitMigrationWritable = VisitRequest & {
  dueAt: IsoDateString
  expiresAt: IsoDateString
}

export const visitMigrationWritableSchema = visitRequestSchema.extend({
  companyGuid: guidSchema,
  maintenancePlanGuid: guidSchema,
  dueAt: isoDateStringSchema,
  expiresAt: isoDateStringSchema,
})

export type VisitWritable = z.infer<typeof visitMigrationWritableSchema>

export type VisitsWriter = AsyncFn<VisitWritable[]>

export type VisitGuidContainer = {
  visitGuid: Guid
}

export type OptionalVisitGuidContainer = {
  visitGuid?: Guid
}

export type VisitJobLinkWriter = AsyncFn<VisitGuidContainer & JobGuidContainer>
export type VisitJobLinkUpsertWriter = AsyncFn<OptionalVisitGuidContainer & JobGuidContainer>

export const unusedVisitStatuses = ['UNSCHEDULED', 'OVERDUE']
export const usedMaintenancePlanVisitStatuses = ['SCHEDULED', 'COMPLETED']

export const isVisitLinkedToJob = (visit: Pick<VisitViewModel, 'visitJob'>) => {
  return !!visit.visitJob
}

export const isVisitStatusUnusedWithoutLinkedJob = (visit: Pick<VisitViewModel, 'status' | 'visitJob'>) => {
  return unusedVisitStatuses.includes(visit.status) && !isVisitLinkedToJob(visit)
}
export const isVisitUnused = (
  visit: Pick<VisitViewModel, 'status' | 'expiresAt' | 'visitJob'>,
  asOfDateTime?: IsoDateString,
) => {
  return (
    isVisitStatusUnusedWithoutLinkedJob(visit) &&
    BzDateFns.isAfter(
      BzDateFns.parseISO(visit.expiresAt, BzDateFns.UTC),
      asOfDateTime ? BzDateFns.parseISO(asOfDateTime, BzDateFns.UTC) : BzDateFns.now(BzDateFns.UTC),
    )
  )
}

export const isVisitLinkedToJobAndUnused = (
  visit: Pick<VisitViewModel, 'status' | 'expiresAt' | 'visitJob'>,
  asOfDateTime?: IsoDateString,
) => {
  return !!visit.visitJob && isVisitUnused(visit, asOfDateTime)
}

export const getNumUnusedMaintenancePlanVisits = (
  visits: Pick<VisitViewModel, 'status' | 'expiresAt'>[],
  asOfDateTime?: IsoDateString,
) => {
  return visits.reduce((acc, visit) => {
    const isUnused = isVisitUnused(visit, asOfDateTime)
    return acc + (isUnused ? 1 : 0)
  }, 0)
}

const isVisitUsed = (visit: Pick<VisitViewModel, 'status' | 'visitJob'>) => {
  return usedMaintenancePlanVisitStatuses.includes(visit.status) || isVisitLinkedToJob(visit)
}

export const getNumUsedMaintenancePlanVisits = (visits: Pick<VisitViewModel, 'status'>[]) => {
  return visits.reduce((acc, visit) => {
    return acc + (isVisitUsed(visit) ? 1 : 0)
  }, 0)
}

export type MaintenancePlanVisitsViewModelReader = AsyncFn<void, VisitViewModel[]>

export const getLastVisitedAtMaintPlansV3 = (visits: VisitViewModel[]): IsoDateString | undefined => {
  const visitsLastVisitedAt = visits
    .map(v => v.visitJob?.workCompletedAt ?? v.completedAtOverride)
    .filter((v): v is IsoDateString => !isNullish(v))
    .sort((a, b) => BzDateFns.compareDesc(BzDateFns.parseISO(a, BzDateFns.UTC), BzDateFns.parseISO(b, BzDateFns.UTC)))

  return visitsLastVisitedAt[0]
}

export const getNumDaysTilNextMaintenanceVisitExpiration = (visits: VisitViewModel[]): number | undefined => {
  const now = BzDateFns.nowISOString()
  return R.sortBy(R.prop('expiresAt'))(visits.filter(x => isVisitUnused(x) && x.expiresAt)).map(x =>
    BzDateFns.differenceInDays(BzDateFns.parseISO(x.expiresAt, BzDateFns.UTC), BzDateFns.parseISO(now, BzDateFns.UTC)),
  )[0]
}

type VisitStatusAffinityDate = { affinityDate?: LocalDateString }
const isVisitOverdue = (v: VisitStatusAffinityDate) => {
  const today = BzDateFns.getTodayLocalDate(BzDateFns.UTC)
  const affinityDate = v.affinityDate

  return (
    affinityDate &&
    (BzDateFns.isBefore(BzDateFns.parseLocalDate(affinityDate), BzDateFns.parseLocalDate(today)) ||
      BzDateFns.isSameDay(BzDateFns.parseLocalDate(affinityDate), BzDateFns.parseLocalDate(today)))
  )
}

type VisitStatusReadableJob = {
  jobLifecycleStatus: {
    specialStatus?: SpecialLifecycleStatus
  }
  appointments: unknown[]
}

const getVisitJobVisitStatus = (job: VisitStatusReadableJob, affinityDate?: LocalDateString): VisitStatus => {
  if (job.jobLifecycleStatus.specialStatus === 'Completed') return 'COMPLETED'
  if ((job.appointments ?? []).length > 0) return 'SCHEDULED'
  return isVisitOverdue({ affinityDate }) ? 'OVERDUE' : 'UNSCHEDULED'
}

export type VisitStatusReadableVisit = {
  expiresAt: IsoDateString
  completedAtOverride?: IsoDateString
  isExpiredOverride?: boolean
  isCompletedOverride?: boolean
  job?: VisitStatusReadableJob
  affinityDate?: LocalDateString
}

export const getVisitStatus = (v: VisitStatusReadableVisit): VisitStatus => {
  if (
    v.isExpiredOverride ||
    BzDateFns.isBefore(BzDateFns.parseISO(v.expiresAt, BzDateFns.UTC), BzDateFns.now(BzDateFns.UTC))
  ) {
    return 'EXPIRED'
  }
  if (v.completedAtOverride || v.isCompletedOverride) return 'COMPLETED'
  if (v.job) return getVisitJobVisitStatus(v.job, v.affinityDate)
  if (isVisitOverdue(v)) return 'OVERDUE'
  return 'UNSCHEDULED'
}

export const getAffinityDateNameByVisitIndex = (visitIndex: number) => {
  const month = defaultAffinityMonthsNumbersJs[visitIndex % 12]
  let season: string

  switch (month) {
    case 11:
    case 0:
    case 1:
      season = 'Winter'
      break
    case 2:
    case 3:
    case 4:
      season = 'Spring'
      break
    case 5:
    case 6:
    case 7:
      season = 'Summer'
      break
    case 8:
    case 9:
    case 10:
      season = 'Fall'
      break
    default:
      season = 'Unknown'
  }

  return `${season} Tune-up`
}

export const getAllInstalledEquipmentGuidsFromVisits = (visits: VisitViewModel[]): Guid[] => {
  return [...new Set(visits.flatMap(v => v.visitEquipment.map(vi => vi.installedEquipmentGuid)))]
}

export const getAllInstalledEquipmentFromVisits = (visits: VisitViewModel[]): VisitEquipmentViewModel[] => {
  const uniqueGuids = new Set<Guid>()
  const uniqueEquipment: VisitEquipmentViewModel[] = []

  visits.forEach(visit => {
    visit.visitEquipment.forEach(equipment => {
      if (!uniqueGuids.has(equipment.installedEquipmentGuid)) {
        uniqueGuids.add(equipment.installedEquipmentGuid)
        uniqueEquipment.push(equipment)
      }
    })
  })

  return uniqueEquipment
}

export type VisitWithAge = Pick<VisitViewModel, 'oldestEquipmentAgeYears'>

export const getOldestEquipmentAgeFromVisits = (visits: VisitWithAge[]): number | undefined => {
  const ages = visits.map(visit => visit.oldestEquipmentAgeYears).filter((age): age is number => !isNullish(age))

  return ages.length > 0 ? Math.max(...ages) : undefined
}

type VisitWithOriginalIndex = VisitViewModel & {
  originalIndex: number
}

export type NextVisitViewModel = VisitViewModel & {
  visitNumber: number
}

export const getNextVisit = (visits: VisitViewModel[]): NextVisitViewModel | undefined => {
  const today = BzDateFns.getTodayLocalDate(BzDateFns.UTC)
  const oneMonthFromNow = BzDateFns.endOfMonth(BzDateFns.addMonths(BzDateFns.parseLocalDate(today), 1))

  // Sort all visits by affinity date and add index
  const sortedVisits = visits
    .map((visit, index) => ({ ...visit, originalIndex: index }))
    .sort((a, b) =>
      BzDateFns.compareAsc(BzDateFns.parseLocalDate(a.affinityDate), BzDateFns.parseLocalDate(b.affinityDate)),
    )

  const eligibleVisits: VisitWithOriginalIndex[] = sortedVisits.filter(
    visit => visit.status !== 'COMPLETED' && visit.status !== 'EXPIRED',
  )

  let nextVisit: VisitWithOriginalIndex | undefined

  for (const visit of eligibleVisits) {
    const affinityDate = BzDateFns.parseLocalDate(visit.affinityDate)

    if (visit.status === 'SCHEDULED') {
      nextVisit = visit
      break
    }

    if (visit.status === 'UNSCHEDULED' || visit.status === 'OVERDUE') {
      if (!nextVisit) {
        nextVisit = visit
      } else {
        const nextVisitAffinityDate = BzDateFns.parseLocalDate(nextVisit.affinityDate)

        // Replace if the current visit is within one month
        if (
          BzDateFns.compareAsc(affinityDate, nextVisitAffinityDate) > 0 &&
          BzDateFns.isBefore(affinityDate, oneMonthFromNow)
        ) {
          nextVisit = visit
        }
      }
    }

    // Stop iterating if we've found a visit and passed the one-month threshold
    if (nextVisit && BzDateFns.isAfter(affinityDate, oneMonthFromNow)) {
      break
    }
  }

  if (nextVisit) {
    const { originalIndex, ...visitData } = nextVisit
    return {
      ...visitData,
      visitNumber: sortedVisits.findIndex(v => v.visitGuid === nextVisit.visitGuid) + 1,
    }
  }

  return undefined
}

type UpdateVisitsParams = {
  existingVisits?: VisitRequest[]
  numVisits: number
  activationDate: LocalDateString
  tzId: TimeZoneId
}

export const createInitialVisitRequests = ({
  existingVisits,
  numVisits,
  activationDate,
  tzId,
}: UpdateVisitsParams): VisitRequest[] => {
  if (existingVisits && existingVisits.length === numVisits) {
    return existingVisits
  }

  const res: VisitRequest[] = existingVisits ? [...existingVisits] : []
  if (res.length > numVisits) {
    res.sort((a, b) => a.affinityDate.localeCompare(b.affinityDate))
    return res.slice(0, numVisits)
  }

  const existingEquipmentGuids = res.length > 0 ? res[0].installedEquipmentGuids : []
  for (let i = res.length; i < numVisits; i++) {
    res.push({
      visitGuid: nextGuid(),
      name: getAffinityDateNameByVisitIndex(i),
      affinityDate: getAffinityDateByVisitIndex(i, activationDate, tzId),
      installedEquipmentGuids: existingEquipmentGuids,
      isCompletedOverride: false,
      shouldLinkToJob: false,
      jobGuid: undefined,
    })
  }

  res.sort((a, b) => a.affinityDate.localeCompare(b.affinityDate))
  return res
}
