import {
  getFinancingRecordType,
  isLoanRecord,
  isPrequalRecord,
  LoanRecord,
  PrequalRecord,
  R,
} from '@breezy/shared'
import { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { WisetackFinancingCard } from 'src/components/Cards/WisetackFinancingCard'
import { useFinancingWizard } from 'src/components/Financing/hooks/useFinancingWizard'
import Switch from 'src/elements/Switch/Switch'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'

export interface WisetackFinancingCollapsibleV2Props {
  accountGuid: string
  loanRecords: LoanRecord[]
  jobGuid?: string
  prequalRecords?: PrequalRecord[]
  allowCreate?: boolean
  refetch?: () => void
}

export const WisetackFinancingCollapsibleV2 = (
  props: WisetackFinancingCollapsibleV2Props,
) => {
  const { loanRecords, prequalRecords } = props

  const totalRecords =
    loanRecords.length + (prequalRecords ? prequalRecords.length : 0)

  const sortedMergedRecords = useMemo<(LoanRecord | PrequalRecord)[]>(
    () =>
      R.sort(R.descend(R.prop('updatedAt')), [
        ...(prequalRecords ? prequalRecords : ([] as PrequalRecord[])),
        ...loanRecords,
      ]),
    [prequalRecords, loanRecords],
  )

  const timezoneId = useExpectedCompanyTimeZoneId()

  const { showFinancingWizard, financingWizard } = useFinancingWizard({
    accountGuid: props.accountGuid,
    jobGuid: props.jobGuid,
    onCancel: props.refetch,
  })

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Wisetack Financing"
      count={totalRecords}
      defaultCollapsed={totalRecords === 0}
      onAdd={props.allowCreate ? showFinancingWizard : undefined}
    >
      <div className="flex flex-col gap-3">
        {sortedMergedRecords.map((record, idx) => (
          <Switch value={getFinancingRecordType(record)} key={idx}>
            {{
              prequal: () =>
                isPrequalRecord(record) ? (
                  <WisetackFinancingCard.PrequalRecordCard
                    prequalRecord={record}
                    timezoneId={timezoneId}
                  />
                ) : null,
              loan: () =>
                isLoanRecord(record) ? (
                  <WisetackFinancingCard.LoanRecordCard
                    loanRecord={record}
                    timezoneId={timezoneId}
                  />
                ) : null,
              default: () => null,
            }}
          </Switch>
        ))}
      </div>

      {financingWizard}
    </OnsitePageCollapsibleSection>
  )
}
