import { isNullish } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { ACCOUNT_METADATA_QUERY } from './AccountDetailsPageV2.gql'
import { AccountMetadata } from './accountDetailsV2Utils'

export const useAccountMetadata = (accountGuid: string | undefined) => {
  const accountMetadataQuery = useQuery({
    query: ACCOUNT_METADATA_QUERY,
    variables: { accountGuid: accountGuid ?? '' },
    pause: isNullish(accountGuid),
  })
  const accountMetadata = useMemo<AccountMetadata | undefined>(
    () => accountMetadataQuery[0].data?.accountsByPk,
    [accountMetadataQuery],
  )

  const refetch = useCallback(() => {
    accountMetadataQuery[1]()
  }, [accountMetadataQuery])

  return {
    accountMetadataQuery,
    accountMetadata,
    fetching: accountMetadataQuery[0].fetching,
    refetch,
  }
}
type WithAccountMetadataProps = {
  accountGuid: string | undefined
  loadingComponent?: JSX.Element
  idleComponent?: JSX.Element
  notFoundComponent?: JSX.Element
  render: (accountMetadata: AccountMetadata, refetch: () => void) => JSX.Element
}

export const WithAccountMetadata = React.memo<WithAccountMetadataProps>(
  ({
    accountGuid,
    render,
    loadingComponent,
    idleComponent = <></>,
    notFoundComponent = <AccountMetadataNotFound />,
  }) => {
    const { accountMetadataQuery, refetch } = useAccountMetadata(accountGuid)

    if (isNullish(accountGuid)) {
      return idleComponent
    }

    return (
      <GqlQueryLoader
        query={accountMetadataQuery}
        loadingComponent={loadingComponent}
        idleComponent={idleComponent}
        render={data => {
          if (!data.accountsByPk) {
            return notFoundComponent
          }
          return render(data.accountsByPk, refetch)
        }}
      />
    )
  },
)

const AccountMetadataNotFound = () => {
  return <div>Account not found</div>
}
